import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import department from "@/components/setting/department/department";
import {useToast} from "vue-toastification";

export interface Department {
    departments: Array<object>
}

export interface DepartmentInfo {
    errors: unknown;
    success: unknown;
    departments: Department;
}

@Module
export default class DepartmentModule extends VuexModule implements DepartmentInfo {
    errors = {};
    success = {};
    departments = {} as Department;

    /**
     * Get authentification success
     * @returns array
     */
    get getDepartmentSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getDepartmentErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getDepartments(): object {
        return this.departments;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched department list data are saved in this function
    [Mutations.SET_DEPARTMENT_LIST](payload) {
        this.departments = payload
    }

    @Mutation // fetched department list data are saved in this function
    [Mutations.SET_DEPARTMENT_LIST_FOR_REG](payload) {
        this.departments = payload
    }

    @Action // fetch departments
    [Actions.FETCH_DEPARTMENT_LIST]() {
        return ApiService.get("/departments").then(({data}) => {
            this.context.commit(Mutations.SET_DEPARTMENT_LIST, data.departments);
        }).catch(({response}) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    }

    @Action // fetch departments for registration
    [Actions.FETCH_DEPARTMENT_LIST_FOR_REG]() {
        return ApiService.get("/reg-departments")
            .then(({data}) => {
                this.context.commit(Mutations.SET_DEPARTMENT_LIST_FOR_REG, data.departments);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // store department
    [Actions.STORE_DEPARTMENT](payload) {
        return ApiService.post("/departments", payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // update department
    [Actions.UPDATE_DEPARTMENT](payload) {
        return ApiService.put("/departments/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action //delete department
    [Actions.DELETE_DEPARTMENT](payload) {
        return ApiService.delete("/departments/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
