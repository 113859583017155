import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SubscriptionEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useToast} from "vue-toastification";

export interface Subscription {
    subscription: object
}

export interface Subscriptions {
    subscriptions: Array<object>
}

export interface TodaySubscriptions {
    today_subscriptions: Array<object>
}

export interface TodayDeliverySubscriptions {
    today_delivery_subscriptions: Array<object>
}

export interface SubscriberDailySubscriptions {
    subscriber_daily_subscriptions: Array<object>
}

export interface SubscriberDailySubscription {
    subscriber_daily_subscription: Array<object>
}

export interface TodaySubscriptionIds {
    today_subscription_ids: Array<object>
}

export interface TodayGuestMealSubscriptions {
    today_guest_meal_subscriptions: Array<object>
}

export interface TodayGuestMealSubscriptionIds {
    today_guest_meal_subscription_ids: Array<object>
}

export interface SubscriptionInfo {
    errors: unknown;
    success: unknown;
    subscriptions: Subscriptions;
    subscription: Subscription;
    today_subscriptions: TodaySubscriptions;
    today_delivery_subscriptions: TodayDeliverySubscriptions;
    subscriber_daily_subscriptions: SubscriberDailySubscriptions;
    today_subscription_ids: TodaySubscriptionIds;
    today_guest_meal_subscriptions: TodayGuestMealSubscriptions;
    subscriber_daily_subscription: SubscriberDailySubscription;
    today_guest_meal_subscription_ids: TodayGuestMealSubscriptionIds;
}

@Module
export default class SubscriptionModule extends VuexModule implements SubscriptionInfo {
    errors = {};
    success = {};
    subscriptions = {} as Subscriptions;
    subscription = {} as Subscription;
    today_subscriptions = {} as TodaySubscriptions;
    today_delivery_subscriptions = {} as TodayDeliverySubscriptions;
    subscriber_daily_subscriptions = {} as SubscriberDailySubscriptions;
    subscriber_daily_subscription = {} as SubscriberDailySubscription;
    today_subscription_ids = {} as TodaySubscriptionIds;
    today_guest_meal_subscriptions = {} as TodayGuestMealSubscriptions;
    today_guest_meal_subscription_ids = {} as TodayGuestMealSubscriptionIds;

    /**
     * Get authentification success
     * @returns array
     */
    get getSubscriptionSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getSubscriptionErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriptions(): object {
        return this.subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscription(): object {
        return this.subscription;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodaySubscriptions(): object {
        return this.today_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodaySubscriptionIds(): object {
        return this.today_subscription_ids;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayGuestMealSubscriptions(): object {
        return this.today_guest_meal_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayGuestMealSubscriptionIds(): object {
        return this.today_guest_meal_subscription_ids;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayDeliverySubscriptions(): object {
        return this.today_delivery_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriberDailySubscriptions(): object {
        return this.subscriber_daily_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriberDailySubscription(): object {
        return this.subscriber_daily_subscription;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_SUBSCRIPTION_LIST](payload) {
        this.subscriptions = payload
    }

    @Mutation // fetched subscription
    [Mutations.SET_LATEST_SUBSCRIPTION](payload) {
        this.subscription = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_TODAY_SUBSCRIPTION_LIST](payload) {
        this.today_subscriptions = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_TODAY_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_subscription_ids = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_SUBSCRIPTION_LIST](payload) {
        this.today_subscriptions = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_subscription_ids = payload
    }

    @Mutation // fetched subscription guest meal list data are saved in this function
    [Mutations.SET_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        this.today_guest_meal_subscriptions = payload
    }

    @Mutation // fetched subscription guest meal list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        this.today_guest_meal_subscriptions = payload
    }

    @Mutation // fetched subscription guest meal ids list data are saved in this function
    [Mutations.SET_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_guest_meal_subscription_ids = payload
    }

    @Mutation // fetched subscription guest meal ids list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_guest_meal_subscription_ids = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_TODAY_DELIVERY_SUBSCRIPTION_LIST](payload) {
        this.today_delivery_subscriptions = payload
    }

    @Mutation
    [Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST](payload) {
        this.subscriber_daily_subscriptions = payload
    }

    @Mutation
    [Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION](payload) {
        this.subscriber_daily_subscription = payload
    }

    @Action // fetch subscriptions
    [Actions.FETCH_SUBSCRIPTION_LIST]() {
        return ApiService.get("/subscriptions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIPTION_LIST, data.subscriptions);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscription
    [Actions.FETCH_LATEST_SUBSCRIPTION]() {
        return ApiService.get("/latest-subscription")
            .then(({data}) => {
                this.context.commit(Mutations.SET_LATEST_SUBSCRIPTION, data.subscription);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscriptions for admin
    [Actions.FETCH_ADMIN_TODAY_SUBSCRIPTION_LIST](payload) {
        return ApiService.post("/admin/today-subscriptions", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_TODAY_SUBSCRIPTION_LIST, data.today_subscriptions);
                this.context.commit(Mutations.SET_ADMIN_TODAY_SUBSCRIPTION_IDS_LIST, data.today_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscriptions
    [Actions.FETCH_TODAY_SUBSCRIPTION_LIST]() {
        return ApiService.get("/today-subscriptions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_SUBSCRIPTION_LIST, data.today_subscriptions);
                this.context.commit(Mutations.SET_TODAY_SUBSCRIPTION_IDS_LIST, data.today_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscription guest meal
    [Actions.FETCH_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST]() {
        return ApiService.get("/subscription/subscriber/today-guest-meal")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST, data.today_guest_meal_subscriptions);
                this.context.commit(Mutations.SET_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST, data.today_guest_meal_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscription guest meal
    [Actions.FETCH_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        return ApiService.post("/subscription/subscriber/admin/today-guest-meal", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST, data.today_guest_meal_subscriptions);
                this.context.commit(Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST, data.today_guest_meal_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today delivery subscriptions
    [Actions.FETCH_TODAY_DELIVERY_SUBSCRIPTION_LIST]() {
        return ApiService.get("/today-delivery-subscriptions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_DELIVERY_SUBSCRIPTION_LIST, data.today_delivery_subscriptions);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber daily subscriptions
    [Actions.FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST](payload) {
        return ApiService.get("/subscriber/daily-subscription/list/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST, data.subscriber_daily_subscriptions);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber daily subscriptions
    [Actions.FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION]() {
        return ApiService.get("/subscriber/daily-subscription")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION, data.subscriber_daily_subscription);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store subscription
    [Actions.STORE_TODAY_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/isDelivered", payload)
            .then(({data}) => {
                swal(data, '#FFF')
            })
            .catch(({response}) => {
                swal(response.data, '#ff5252')
            });
    }

    @Action // store all subscriber subscription
    [Actions.STORE_TODAY_ALL_SUBSCRIBER_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/all/isDelivered", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store subscription guest meal
    [Actions.STORE_TODAY_GUEST_MEAL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/guest-meal/isDelivered", payload)
            .then(({data}) => {
                swal(data, '#FFF')
            })
            .catch(({response}) => {
                swal(response.data, '#ff5252')
            });
    }

    @Action // store subscription all guest meal
    [Actions.STORE_TODAY_ALL_GUEST_MEAL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/all-guest-meal/isDelivered", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }


    @Action // start subscription
    [Actions.START_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/start-subscription", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // cancel subscription
    [Actions.CANCEL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/cancel-subscription", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // admin today subscription Excel download
    [Actions.ADMIN_TODAY_SUBSCRIPTION_EXCEL_DOWNLOAD](payload) {
        return ApiService.pdfDownloadPost("/admin/subscription/today/excel/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'today_subscription.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // admin today subscription Excel download
    [Actions.KITCHEN_COUNTER_TODAY_SUBSCRIPTION_EXCEL_DOWNLOAD](payload) {
        return ApiService.pdfDownloadPost("/kitchen-counter/subscription/today/excel/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'today_subscription.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}

const swal = (data, color) => {
    let timerInterval

    if (color === '#FFF') {
        Swal.fire({
            title: data.user ? data.user.name : 'No User Found',
            html: data.message,
            imageUrl: data.user ? data.user.profile_picture_url : '/media/avatars/dead_emoji.png',
            imageWidth: 300,
            imageHeight: 200,
            imageAlt: 'No Image Found',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            background: color,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        })
    } else {
        Swal.fire({
            title: `<span style="color:#fff">${data.user ? data.user.name : 'No User Found'}</span>`,
            html: `<h6 style="color:#fff">${data.message}</h6>`,
            imageUrl: data.user ? data.user.profile_picture_url : '/media/avatars/dead_emoji.png',
            imageWidth: data.user ? 300 : 200,
            imageHeight: 200,
            imageAlt: 'No Image Found',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            background: color,
            width: 350,
            padding: '1em',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        })
    }
}
