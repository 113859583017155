import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface PaymentType {
    payment_types: Array<object>
}

export interface PaymentTypeInfo {
    errors: unknown;
    success: unknown;
    payment_types: PaymentType;
}

@Module
export default class PaymentTypeModule extends VuexModule implements PaymentTypeInfo{
    errors = {};
    success = {};
    payment_types = {} as PaymentType;

    /**
     * Get authentification success
     * @returns array
     */
    get getPaymentTypeSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getPaymentTypeErrors() {
        return this.errors;
    }

    /**
     * shows payment type list
     * @returns object
     */
    get getPaymentTypes(): object {
        return this.payment_types;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched payment type list data are saved in this function
    [Mutations.SET_PAYMENT_TYPE_LIST](payload) {
        this.payment_types = payload
    }

    @Action // fetch payment_types
    [Actions.FETCH_PAYMENT_TYPE_LIST]() {
        return ApiService.get("/payment-types")
            .then(({data}) => {
                this.context.commit(Mutations.SET_PAYMENT_TYPE_LIST, data.payment_types);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
