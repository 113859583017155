import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Locations {
    locations: Array<object>
}

export interface MonthlyFoodDeliveryStatuses {
    monthly_food_delivery_statuses: Array<object>
}

export interface MonthlyFoodDeliveryStatusInfo {
    errors: unknown;
    success: unknown;
    locations: Locations;
    monthly_food_delivery_statuses: MonthlyFoodDeliveryStatuses;
}

@Module
export default class MonthlyFoodDeliveryStatusModule extends VuexModule implements MonthlyFoodDeliveryStatusInfo {
    errors = {};
    success = {};
    locations = {} as Locations;
    monthly_food_delivery_statuses = {} as MonthlyFoodDeliveryStatuses;

    /**
     * shows user list
     * @returns object
     */
    get getMonthlyFoodDeliveryStatusReport(): object {
        return this.monthly_food_delivery_statuses;
    }

    /**
     * shows user list
     * @returns object
     */
    get getLocationReport(): object {
        return this.locations;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_MONTHLY_FOOD_DELIVERY_STATUS_LIST](payload) {
        this.monthly_food_delivery_statuses = payload
    }

    @Mutation
    [Mutations.SET_LOCATION_LIST](payload) {
        this.locations = payload
    }

    @Action // fetch monthly kitchen costing
    [Actions.FETCH_MONTHLY_FOOD_DELIVERY_STATUS_LIST](payload) {
        return ApiService.post("/report/monthly-food-delivered-status", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_MONTHLY_FOOD_DELIVERY_STATUS_LIST, data.monthly_food_delivery_statuses);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch monthly kitchen costing
    [Actions.FETCH_LOCATION_LIST]() {
        return ApiService.get("/report/locations")
            .then(({data}) => {
                this.context.commit(Mutations.SET_LOCATION_LIST, data.locations);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // monthly kitchen costing download as pdf
    [Actions.FETCH_MONTHLY_FOOD_DELIVERY_STATUS_DOWNLOAD_PDF](payload) {
        return ApiService.pdfDownloadPost("/report/monthly-food-delivered-status/download-pdf", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'monthly_food_delivery_status.pdf')
                document.body.appendChild(link)
                link.click()
             })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // monthly kitchen costing download as excel
    [Actions.FETCH_MONTHLY_FOOD_DELIVERY_DOWNLOAD_EXCEL](payload) {
        return ApiService.pdfDownloadPost("/report/monthly-food-delivered-status/download-excel", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'monthly_food_delivery_status.xlsx')
                document.body.appendChild(link)
                link.click()
             })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
