import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface SubscriberAndUnsubscribeData {
    subscriber_and_unsubscribe_report_data: object,
}

@Module
export default class SubscriberAndUnsubscribeReportModule extends VuexModule implements SubscriberAndUnsubscribeData {

    subscriber_and_unsubscribe_report_data = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getSubscriberAndUnsubscribeReport(): object {
        return this.subscriber_and_unsubscribe_report_data;
    }

    @Mutation // setCampaignMealReport
    [Mutations.SET_SUBSCRIBER_AND_UNSUBSCRIBE_REPORT](payload) {
        this.subscriber_and_unsubscribe_report_data = payload
    }

    @Action // fetchCampaignMealReport
    [Actions.FETCH_SUBSCRIBER_AND_UNSUBSCRIBE_REPORT](payload) {
        return ApiService.post("/report/subscriber-and-unsubscribe-report", payload).then(({data}) => {
            this.context.commit(Mutations.SET_SUBSCRIBER_AND_UNSUBSCRIBE_REPORT, data.subscriber_and_unsubscribe_report_data);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // exportCampaignMealReport
    [Actions.EXPORT_SUBSCRIBER_AND_UNSUBSCRIBE_REPORT](payload) {
        const year = payload.year

        return ApiService.pdfDownloadPost("/report/subscriber-and-unsubscribe-report/download", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', year + '_subscriber_and_unsubscribe_report.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
