import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/InventoryEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface KitchenStockData {
    kitchenStock: object,
}

@Module
export default class KitchenStockModule extends VuexModule implements KitchenStockData {
    kitchenStock = {};

    /**
     * shows kitchen stock
     * @returns object
     */
    get getKitchenStock(): object {
        return this.kitchenStock;
    }

    @Mutation // setKitchenStock
    [Mutations.SET_KITCHEN_STOCK](payload) {
        this.kitchenStock = payload
    }

    @Action // fetchKitchenStock
    [Actions.FETCH_KITCHEN_STOCK](payload) {
        return ApiService.get("/kitchen-station/stock/" + payload).then(({data}) => {
            this.context.commit(Mutations.SET_KITCHEN_STOCK, data.kitchenItemStock);
        }).catch(({response}) => {
            toast('error', response.data)
            console.log('KitchenStockModule [FETCH_KITCHEN_STOCK]', response)
        });
    }

    @Action
    [Actions.EXPORT_INVENTORY_REPORT](payload) {
        console.log(payload)
        return ApiService.pdfDownloadPost("/kitchen-station/stock-export", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'inventory_report.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [EXPORT_INVENTORY_REPORT]', response)
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}

