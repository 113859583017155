import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Mutations, Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

// const isUserAuthenticated = computed(() => store.getters.isUserAuthenticated);

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/layout/Layout.vue"),
        meta: {
            authRequired: true,
            hidden: true,
        },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {permissions: []},
                component: () => import("@/views/Dashboard.vue"),
            },

            // users profile route
            {
                path: "/profile",
                name: "profile-layout",
                meta: {permissions: []},
                component: () => import("@/components/Layout.vue"),
                children: [
                    {
                        path: "",
                        name: "profile",
                        meta: {permissions: []},
                        component: () => import("@/views/user/account/Profile.vue"),
                    },
                ]
            },
            // end users profile route

            // users route
            {
                path: "/users",
                name: "users-layout",
                meta: {permissions: []},
                component: () => import("@/components/Layout.vue"),
                children: [
                    {
                        path: "",
                        name: "users",
                        meta: {permissions: []},
                        component: () => import("@/views/user/Index.vue"),
                    },
                    {
                        path: "create",
                        name: "users-create",
                        meta: {permissions: []},
                        component: () => import("@/components/user/Create.vue"),
                    },
                    {
                        path: "edit/:id",
                        name: "users-edit",
                        meta: {permissions: []},
                        component: () => import("@/components/user/Edit.vue"),
                    },
                    {
                        path: "details/:id",
                        name: "users-details",
                        meta: {permissions: []},
                        component: () => import("@/views/user/Details.vue"),
                    },
                ]
            },


            // Start user route
            {
                path: "/hr/users",
                name: "hr-users",
                meta: {permissions: []},
                component: () => import("@/views/hr-user/Index.vue"),
            },
            // End user route

            // consumption route
            {
                path: "/consumption",
                name: "consumption",
                meta: {permissions: []},
                component: () => import("@/components/Layout.vue"),
                children: [
                    {
                        path: "create",
                        name: "consumption-create",
                        meta: {permissions: []},
                        component: () => import("@/views/consumption/Add.vue"),
                    },
                    {
                        path: "index",
                        name: "consumption-index",
                        meta: {permissions: []},
                        component: () => import("@/views/consumption/Index.vue"),
                    },
                ]
            },

            //inventory
            {
                path: "/inventory",
                name: "inventory",
                meta: {permissions: []},
                component: () => import("@/components/Layout.vue"),
                children: [
                    {
                        path: "stock",
                        name: "kitchen-stock",
                        meta: {permissions: []},
                        component: () => import("@/views/inventory/Stock.vue"),
                    },
                ]
            },

            // Start purchase route
            {
                path: "/purchase-requests",
                name: "purchase-requests",
                meta: {permissions: []},
                component: () => import("@/components/Layout.vue"),
                children: [
                    {
                        path: "create",
                        name: "purchase-request-create",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-request/kitchen-manager/Create.vue"),
                    },
                    {
                        path: "edit/:id",
                        name: "purchase-request-edit",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-request/kitchen-manager/Edit.vue"),
                    },
                    {
                        path: "list",
                        name: "purchase-request-list",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-request/kitchen-manager/Index.vue"),
                    },
                    {
                        path: "show/:id",
                        name: "purchase-request-show",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-request/Show.vue"),
                    },
                    {
                        path: "admin/list",
                        name: "purchase-request-admin-list",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-request/admin/Pending.vue"),
                    },
                ]
            },

            {
                path: "/purchase-order/:pr_number",
                name: "purchase-order",
                meta: {permissions: []},
                component: () => import("@/components/Layout.vue"),
                children: [
                    {
                        path: "create",
                        name: "purchase-order-create",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-order/Create.vue"),
                    },
                    {
                        path: "index",
                        name: "purchase-order-index",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-order/Index.vue"),
                    },
                    {
                        path: "show/:id",
                        name: "purchase-order-show",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-order/Show.vue"),
                    },
                    {
                        path: "inbound/:purchaseOrderId",
                        name: "purchase-order-inbound",
                        meta: {permissions: []},
                        component: () => import("@/views/purchase/purchase-order-inbound/Inbound.vue"),
                    },
                ]
            },
            // End purchase route

            // start permission
            {
                path: "/permissions",
                name: "permissions",
                meta: {permissions: []},
                component: () => import("@/views/permission/permission/Index.vue"),
            },
            {
                path: "/roles",
                name: "roles",
                meta: {permissions: []},
                component: () => import("@/views/permission/role/Index.vue"),
            },
            {
                path: "/roles/create",
                name: "roles-create",
                meta: {permissions: []},
                component: () => import("@/components/permission/role/Add.vue"),
            },
            {
                path: "/roles/update/:role_id",
                name: "roles-update",
                meta: {permissions: []},
                component: () => import("@/components/permission/role/Edit.vue"),
            },
            // End permission

            // Start campaign meal
            {
                path: "/subscriber/campaign-meal/list",
                name: "subscriber-campaign-meal-list",
                meta: {permissions: []},
                component: () => import("@/views/campaign/subscriber/Index.vue"),
            },
            {
                path: "/subscriber/campaign-meal/create",
                name: "subscriber-campaign-meal-create",
                meta: {permissions: []},
                component: () => import("@/components/campaign/subscriber/Add.vue"),
            },
            {
                path: "/subscriber/campaign-meal/update/:campaign_meal_id",
                name: "subscriber-campaign-meal-update",
                meta: {permissions: []},
                component: () => import("@/components/campaign/subscriber/Edit.vue"),
            },
            {
                path: "/admin/campaign-meal/list",
                name: "admin-campaign-meal-list",
                meta: {permissions: []},
                component: () => import("@/views/campaign/admin/Index.vue"),
            },
            {
                path: "/kitchen-manager/campaign-meal/list",
                name: "kitchen-manager-campaign-meal-list",
                meta: {permissions: []},
                component: () => import("@/views/campaign/kitchen-manager/Index.vue"),
            },
            // End permission

            // start menu page
            {
                path: "/menu-pages",
                name: "menu-pages",
                meta: {permissions: []},
                component: () => import("@/views/menu-page/page/Index.vue"),
            },
            {
                path: "/menu-pages/create",
                name: "menu-pages-create",
                meta: {permissions: []},
                component: () => import("@/components/menu-page/page/Add.vue"),
            },
            {
                path: "/menu-pages/update/:menu_page_id",
                name: "menu-pages-update",
                meta: {permissions: []},
                component: () => import("@/components/menu-page/page/Edit.vue"),
            },
            {
                path: "/menu-page-subs",
                name: "menu-page-subs",
                meta: {permissions: []},
                component: () => import("@/views/menu-page/page-sub/Index.vue"),
            },
            {
                path: "/menu-page-subs/create",
                name: "menu-page-subs-create",
                meta: {permissions: []},
                component: () => import("@/components/menu-page/page-sub/Add.vue"),
            },
            {
                path: "/menu-page-subs/update/:menu_page_sub_id",
                name: "menu-page-subs-update",
                meta: {permissions: []},
                component: () => import("@/components/menu-page/page-sub/Edit.vue"),
            },
            // End menu page

            // Start setting route
            {
                path: "/setting",
                name: "setting",
                meta: {permissions: []},
                component: () => import("@/views/setting/layout.vue"),
                children: [
                    // start item
                    {
                        path: "items",
                        name: "items",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/item/Index.vue"),
                    },
                    // End item

                    // Start hr-brand
                    {
                        path: "hr-brands",
                        name: "hr-brands",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/hr-brand/Index.vue"),
                    },
                    // End hr-brand

                    // Start kitchen-station
                    {
                        path: "kitchen-station",
                        name: "kitchen-station",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/kitchen-station/Index.vue"),
                    },
                    // End kitchen-station

                    // Start kitchen-station
                    {
                        path: "kitchen-supply-hub",
                        name: "kitchen-supply-hub",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/kitchen-supply-hub/Index.vue"),
                    },
                    // End kitchen-station

                    // Start vendor
                    {
                        path: "vendor",
                        name: "vendor",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/vendor/Index.vue"),
                    },
                    // End vendor

                    // Start Payment Type
                    {
                        path: "payment-types",
                        name: "payment-types",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/payment-type/Index.vue"),
                    },
                    // End Payment Type

                    // Start Department
                    {
                        path: "departments",
                        name: "departments",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/department/Index.vue"),
                    },
                    // End Department

                    // Start Location
                    {
                        path: "locations",
                        name: "locations",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/location/Index.vue"),
                    },
                    // End Location

                    // Start Location
                    {
                        path: "government-holiday",
                        name: "government-holiday",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/government-holiday/Index.vue"),
                    },
                    // End Location

                    // Start Food Item
                    {
                        path: "food-items",
                        name: "food-items",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/food-item/Index.vue"),
                    },
                    // End Food Item

                    // Start Daily Food Item
                    {
                        path: "daily-food-items",
                        name: "daily-food-items",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/daily-food-item/Index.vue"),
                    },
                    {
                        path: "daily-food-items/create",
                        name: "daily-food-items-create",
                        meta: {permissions: []},
                        component: () => import("@/components/setting/daily-food-item/Add.vue"),
                    },
                    // End Daily Food Item

                    // Start d-feast
                    {
                        path: "d-feast",
                        name: "d-feast",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/d-feast/Index.vue"),
                    },
                    // End d-feast

                    // Start Term and Condition
                    {
                        path: "term-and-condition",
                        name: "term-and-condition",
                        meta: {permissions: []},
                        component: () => import("@/views/setting/term-and-condition/Index.vue"),
                    },
                    // End Term and Condition
                ]
            },
            // End setting route
            // Start Subscription route
            {
                path: "/subscriber/subscription/list",
                name: "subscriber-subscription-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/subscriber/Index.vue"),
            },
            {
                path: "/subscriber/daily-subscription/list/:subscription_id",
                name: "subscriber-daily-subscription-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/subscriber/DailySubscriptionList.vue"),
            },
            {
                path: "/kitchen-counter/today/subscription/list",
                name: "kitchen-counter-today-subscription-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/kitchen-counter/SubscriberMeal.vue"),
            },
            {
                path: "/kitchen-counter/today/guest-meal/list",
                name: "kitchen-counter-today-guest-meal-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/kitchen-counter/GuestMeal.vue"),
            },
            {
                path: "/kitchen-manager/today/subscription/counter",
                name: "kitchen-manager-today-subscription-counter",
                meta: {permissions: []},
                component: () => import("@/views/subscription/kitchen-manager/Index.vue"),
            },
            {
                path: "/kitchen-counter/today/subscription/delivery",
                name: "kitchen-counter-today-subscription-delivery",
                meta: {permissions: []},
                component: () => import("@/views/subscription/kitchen-counter/TodayDelivery.vue"),
            },
            {
                path: "/admin/today/subscription/list",
                name: "admin-today-subscription-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/admin/SubscriberMeal.vue"),
            },
            {
                path: "/admin/today/guest-meal/list",
                name: "admin-delivery-guest-meal-today-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/admin/GuestMeal.vue"),
            },
            {
                path: "/admin/today/subscription/delivery",
                name: "admin-today-subscription-delivery",
                meta: {permissions: []},
                component: () => import("@/views/subscription/admin/TodayDelivery.vue"),
            },
            {
                path: "/subscriber/alternative-guest-meal/list",
                name: "subscriber-alternative-guest-meal-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/alternative-schedule/guest-meal/Index.vue"),
            },
            {
                path: "/subscriber/alternative-guest-meal/create",
                name: "subscriber-alternative-guest-meal-create",
                meta: {permissions: []},
                component: () => import("@/components/subscription/alternative-schedule/guest-meal/Add.vue"),
            },
            {
                path: "/subscriber/alternative-location/list",
                name: "subscriber-alternative-location-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/alternative-schedule/location/Index.vue"),
            },
            {
                path: "/subscriber/alternative-location/create",
                name: "subscriber-alternative-location-create",
                meta: {permissions: []},
                component: () => import("@/components/subscription/alternative-schedule/location/Add.vue"),
            },
            {
                path: "/admin/subscriber/list",
                name: "admin-subscriber-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/admin/subscriber/Index.vue"),
            },
            {
                path: "/admin/cancelled-subscriber/list",
                name: "admin-cancelled-subscriber-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/admin/subscriber/CancelledSubscriber.vue"),
            },
            {
                path: "/admin/subscriber/subscription/list/:user_id",
                name: "admin-subscriber-subscription-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/admin/subscriber/Subscription.vue"),
            },
            {
                path: "/admin/subscriber/daily-subscription/list/:subscription_id",
                name: "admin-subscriber-daily-subscription-list",
                meta: {permissions: []},
                component: () => import("@/views/subscription/admin/subscriber/DailySubscriptionList.vue"),
            },
            //End Subscription

            // consumption route
            {
                path: "/admin/subscriber/account/list",
                name: "admin-subscriber-account-list",
                meta: {permissions: []},
                component: () => import("@/views/account/admin/Index.vue"),
            },
            {
                path: "/subscriber/account/list",
                name: "subscriber-account-list",
                meta: {permissions: []},
                component: () => import("@/views/account/subscriber/Index.vue"),
            },
            // excel import
            {
                path: "/subscriber/import",
                name: "subscriber-import",
                meta: {permissions: []},
                component: () => import("@/views/excel/import/Subscriber.vue"),
            },
            // term and condition route
            {
                path: "/term-and-condition",
                name: "subscriber-term-and-condition",
                component: () => import("@/views/subscriber/term-and-condition/index.vue"),
            },
            // Admin subscriber guest meal
            {
                path: "/admin/subscriber/alternative-guest-meals/:user_id",
                name: "admin-subscriber-alternative-guest-meals",
                component: () => import("@/views/subscription/admin/subscriber/RequestGuestMeal.vue"),
            },
            // Admin subscriber location
            {
                path: "/admin/subscriber/alternative-locations/:user_id",
                name: "admin-subscriber-alternative-locations",
                component: () => import("@/views/subscription/admin/subscriber/RequestLocation.vue"),
            },
            // Subscriber and Unsubscribe list
            {
                path: "/report/subscriber-and-unsubscribe",
                name: "admin-subscriber-and-unsubscribe",
                component: () => import("@/views/report/MonthlySubscriberAndUnsubscribeListReport.vue"),
            },
            // Monthly purchase request list
            {
                path: "/report/monthly-purchase-request-list",
                name: "admin-report-monthly-purchase-request-list",
                component: () => import("@/views/report/MonthlyPRListReport.vue"),
            },
            // About route
            {
                path: "/about",
                name: "about",
                component: () => import("@/views/about/Index.vue"),
            },
            // About route
            {
                path: "/support",
                name: "support",
                component: () => import("@/views/support/Index.vue"),
            },
            // Chat route
            {
                path: "/chat",
                name: "chat",
                component: () => import("@/views/chat/Subscriber.vue"),
            },
            // Begin::Report Route

            // Inbound route
            {
                path: "/report/inbound-report",
                name: "report-inbound-report",
                component: () => import("@/views/report/InboundReport.vue"),
            },

            // Kitchen Inventory route
            {
                path: "/report/inventory-history",
                name: "report-inventory-history",
                component: () => import("@/views/report/InventoryHistory.vue"),
            },

            // Kitchen Inventory route
            {
                path: "/report/inventory-report",
                name: "report-inventory-report",
                component: () => import("@/views/report/InventoryReport.vue"),
            },

            // consumption report
            {
                path: "/report/consumption-report",
                name: "report-consumption-report",
                component: () => import("@/views/report/ConsumptionReport.vue"),
            },

            // inbound consumption report
            {
                path: "/report/inbound-consumption-report",
                name: "report-inbound-consumption-report",
                component: () => import("@/views/report/InboundConsumptionReport.vue"),
            },

            {
                path: "/report/monthly-kitchen-costing",
                name: "report-monthly-kitchen-costing",
                component: () => import("@/views/report/MonthlyKitchenCosting.vue"),
            },

            {
                path: "/report/monthly-food-delivery-status",
                name: "report-monthly-food-delivery-status",
                component: () => import("@/views/report/MonthlyFoodDeliveryStatus.vue"),
            },

            {
                path: "/report/daily-guest-meal",
                name: "report-daily-guest-meal",
                component: () => import("@/views/report/DailyGuestMeal.vue"),
            },

            {
                path: "/report/campaign-meal",
                name: "report-campaign-meal",
                component: () => import("@/views/report/CampaignMealReport.vue"),
            },

            // END::Report Route
        ]
    },
    {
        path: "/",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
            {
                path: "/sign-in",
                name: "sign-in",
                component: () =>
                    import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
            },
            {
                path: "/sign-up",
                name: "sign-up",
                component: () =>
                    import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
            },
            {
                path: "/password-reset",
                name: "password-reset",
                component: () =>
                    import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
            },
            {
                path: "/update-password/:token",
                name: "update-password",
                component: () =>
                    import("@/views/crafted/authentication/basic-flow/UpdatePassword.vue"),
            },
        ],
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router.beforeEach(() => {
//   // reset config to initial state
//   store.commit(Mutations.RESET_LAYOUT_CONFIG);
//
//   store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
//
//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

router.beforeEach(async (to, from, next) => {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    await store.dispatch(Actions.VERIFY_AUTH, {api_token: JwtService.getToken()});

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    if (to.matched.some(record => record.meta && record.meta.authRequired)) {
        if (!store.getters.isUserAuthenticated && !JwtService.getToken() && !JwtService.getMenu()) {
            next({
                name: "sign-in",
                query: {redirect: to.fullPath}
            })
        } else {
            next();
        }
    } else {
        next();
    }
})

export default router;
