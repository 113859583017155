import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/PurchaseEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface PurchaseOrderInboundData {
    purchaseOrderInboundItems: object,
}

@Module
export default class PurchaseOrderInboundModule extends VuexModule implements PurchaseOrderInboundData {

    purchaseOrderInboundItems = {};

    /**
     * shows purchase order detail
     * @returns object
     */
    get getPurchaseOrderInboundItems(): object {
        return this.purchaseOrderInboundItems;
    }

    @Mutation // set purchase request list
    [Mutations.SET_PURCHASE_ORDER_INBOUND_ITEMS](payload) {
        this.purchaseOrderInboundItems = payload
    }

    @Action // fetchPurchaseOrderInboundItems
    [Actions.FETCH_PURCHASE_ORDER_INBOUND_ITEMS](payload) {
        return ApiService.get("/purchase-order-inbound/" + payload + "/items")
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_ORDER_INBOUND_ITEMS, data.purchase_order_items);
            })
            .catch(({response}) => {
                console.log('PurchaseOrderInboundModule [FETCH_PURCHASE_ORDER_INBOUND_ITEMS]', response)
                toast('error', response.data)
            });
    }

    @Action // updatePurchaseOrderInbound
    [Actions.UPDATE_PURCHASE_ORDER_INBOUND](payload) {
        return ApiService.post("/purchase-order-inbound", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.log('PurchaseOrderInboundModule [UPDATE_PURCHASE_ORDER_INBOUND]', response)
                toast('error', response.data)
            });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
