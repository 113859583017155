import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface InboundHistoryData {
    inventory_history: object,
}

@Module
export default class InboundHistoryModule extends VuexModule implements InboundHistoryData {

    inventory_history = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getInboundHistory(): object {
        return this.inventory_history;
    }

    @Mutation // setInboundHistory
    [Mutations.SET_INBOUND_HISTORY](payload) {
        this.inventory_history = payload
    }

    @Action // fetchInboundHistory
    [Actions.FETCH_INBOUND_HISTORY](payload) {
        return ApiService.post("/report/inbound-history", payload).then(({data}) => {
            this.context.commit(Mutations.SET_INBOUND_HISTORY, data.inbound_history);
        }).catch(({response}) => {
            console.log('InboundHistoryModule [FETCH_INBOUND_HISTORY]', response)
            toast('error', response.data)
        });
    }

    @Action // exportInboundHistory
    [Actions.EXPORT_INBOUND_HISTORY](payload) {
        const from = moment(payload.date[0]).format('YYYY-MM-DD')
        const to = moment(payload.date[1]).format('YYYY-MM-DD')

        return ApiService.pdfDownloadPost("/report/inbound-history/export", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', from + '_' + to + '_'+ payload.name +'_InboundHistory.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('InboundHistoryModule [EXPORT_INBOUND_HISTORY]', response)
            toast('error', response.data)
        });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
