import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface KitchenSupplyHubData {
    KitchenSupplyHubList: object,
    HubKitchenStationList: object,
}

@Module
export default class KitchenSupplyHubModule extends VuexModule implements KitchenSupplyHubData {

    KitchenSupplyHubList = {};
    HubKitchenStationList = {};

    /**
     * shows Kitchen Supply Hub list
     * @returns object
     */
    get getKitchenSupplyHubList(): object {
        return this.KitchenSupplyHubList;
    }

    get getHubKitchenSupplyHubList(): object {
        return this.HubKitchenStationList;
    }

    @Mutation // fetched Kitchen-Supply-Hub list data are saved in this function
    [Mutations.SET_KITCHEN_SUPPLY_HUB_LIST](payload) {
        this.KitchenSupplyHubList = payload
    }

    @Mutation // fetched Kitchen-Supply-Hub list data are saved in this function
    [Mutations.SET_HUB_KITCHEN_STATION_LIST](payload) {
        this.HubKitchenStationList = payload
    }

    @Action // fetchKitchenSupplyHubList
    [Actions.FETCH_KITCHEN_SUPPLY_HUB_LIST]() {
        return ApiService.get("/kitchen-supply-hubs")
            .then(({data}) => {
                this.context.commit(Mutations.SET_KITCHEN_SUPPLY_HUB_LIST, data.kitchen_supply_hubs);
            })
            .catch(({response}) => {
                console.error('KitchenSupplyHubModule [FETCH_KITCHEN_SUPPLY_HUB_LIST]', response.data.message)
            });
    }

    @Action // storeKitchenSupplyHub
    [Actions.STORE_KITCHEN_SUPPLY_HUB](payload) {
        return ApiService.post("/kitchen-supply-hubs", payload.formData)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('KitchenSupplyHubModule [STORE_KITCHEN_SUPPLY_HUB]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action // updateKitchenSupplyHub
    [Actions.UPDATE_KITCHEN_SUPPLY_HUB](payload) {
        return ApiService.put("/kitchen-supply-hubs/" + payload.formData.id, payload.formData)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('KitchenSupplyHubModule [UPDATE_KITCHEN_SUPPLY_HUB]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action //deleteKitchenSupplyHub
    [Actions.DELETE_KITCHEN_SUPPLY_HUB](payload) {
        return ApiService.delete("/kitchen-supply-hubs/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('KitchenSupplyHubModule [DELETE_KITCHEN_SUPPLY_HUB]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action //fetchHubKitchenStationList
    [Actions.FETCH_HUB_KITCHEN_STATION_LIST]() {
        return ApiService.get("kitchen-supply-hub/all-kitchen-stations")
            .then(({data}) => {
                this.context.commit(Mutations.SET_HUB_KITCHEN_STATION_LIST, data.kitchen_stations);
            })
            .catch(({response}) => {
                console.error('KitchenSupplyHubModule [FETCH_KITCHEN_SUPPLY_HUB_LIST]', response.data.message)
            });
    }


}


// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}