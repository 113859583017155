import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/PurchaseEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import router from "@/router";

export interface PurchaseOrderData {
    purchaseOrder: object,
    purchaseOrderList: object,
}

@Module
export default class PurchaseOrderModule extends VuexModule implements PurchaseOrderData {

    purchaseOrder = {};
    purchaseOrderList = {};
    purchase_request_items = {};


    /**
     * shows purchase order detail
     * @returns object
     */
    get getPurchaseOrder(): object {
        return this.purchaseOrder;
    }


    /**
     * shows purchase orders list
     * @returns object
     */
    get getPurchaseOrders(): object {
        return this.purchaseOrderList;
    }

    /**
     * shows purchase order item list
     * @returns object
     */
    get getPurchaseOrderItems(): object {
        return this.purchase_request_items;
    }

    @Mutation // set purchase request list
    [Mutations.SET_PURCHASE_ORDER](payload) {
        this.purchaseOrder = payload
    }

    @Mutation // set purchase request list
    [Mutations.SET_PURCHASE_ORDER_LIST](payload) {
        this.purchaseOrderList = payload
    }

    @Mutation // set purchase request items list
    [Mutations.SET_PURCHASE_REQUEST_ITEM_DETAIL](payload) {
        this.purchase_request_items = payload
    }

    // @Action // fetchItems
    // [Actions.FETCH_ITEMS]() {
    //     return ApiService.get("/purchase-request/all-info")
    //         .then(({data}) => {
    //             this.context.commit(Mutations.SET_ITEMS, data.items);
    //         })
    //         .catch(({response}) => {
    //             console.log('PurchaseOrderModule [FETCH_ITEM_LIST]', response)
    //             toast('error', response.data)
    //         });
    // }

    @Action // fetchPurchaseRequestList
    [Actions.FETCH_PURCHASE_ORDER_LIST](payload) {

        return ApiService.post("/purchase-order", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_ORDER_LIST, data.purchase_orders);
            })
            .catch(({response}) => {
                console.log('PurchaseOrderModule [FETCH_PURCHASE_ORDER_LIST]', response)
                toast('error', response.data)
            });
    }

    @Action // createPurchaseRequest
    [Actions.CREATE_PURCHASE_ORDER](payload) {

        return ApiService.post("/purchase-order/store", payload)
            .then(({data}) => {
                toast('success', data)
                router.push({name: 'purchase-order-index'})
            })
            .catch(({response}) => {
                console.error('PurchaseOrderModule [CREATE_PURCHASE_ORDER]', response.data.message)
                toast('error', response.data)
            });

    }

    @Action // showPurchaseOrder
    [Actions.SHOW_PURCHASE_ORDER](payload) {

        return ApiService.get("/purchase-order/show/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_ORDER, data.purchase_order);
            })
            .catch(({response}) => {
                console.error('PurchaseOrderModule [SHOW_PURCHASE_ORDER]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action // editPurchaseRequest
    [Actions.EDIT_PURCHASE_ORDER](payload) {
        return ApiService.get("/purchase-order/edit/" + payload.id,)
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_REQUEST_DETAIL, data.items);
            })
            .catch(({response}) => {
                console.log('PurchaseOrderModule [EDIT_PURCHASE_ORDER]', response)
                toast('error', response.data)
            });
    }

    @Action // updatePurchaseRequest
    [Actions.UPDATE_PURCHASE_ORDER](payload) {
        return ApiService.put("/purchase-order/update/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('PurchaseOrderModule [UPDATE_PURCHASE_ORDER]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action // deletePurchaseRequest
    [Actions.DELETE_PURCHASE_ORDER](payload) {
        return ApiService.delete("/purchase-order/delete/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('PurchaseOrderModule [DELETE_PURCHASE_ORDER]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action // exportExcelPurchaseOrder
    [Actions.EXPORT_EXCEL_PURCHASE_ORDER](payload) {
        return ApiService.pdfDownloadPost("/purchase-order/export-excel", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', payload.purchaseOrderName+'.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                console.error('PurchaseOrderModule [EXPORT_EXCEL_PURCHASE_ORDER]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action // exportPurchaseRequest
    [Actions.EXPORT_PURCHASE_ORDER](payload) {
        return ApiService.pdfDownloadPost("/purchase-order/export-pdf", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'purchase_order.pdf')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                console.error('PurchaseOrderModule [EXPORT_PURCHASE_ORDER]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action // Get purchase request
    [Actions.GET_PURCHASE_REQUEST_ITEMS](payload) {
        return ApiService.get("/purchase-request/items/" + payload.id,)
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_REQUEST_DETAIL, data.purchase_request);
                this.context.commit(Mutations.SET_PURCHASE_REQUEST_ITEM_DETAIL, data.items);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
