import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Vendor {
    vendors: Array<object>
}

export interface VendorInfo {
    errors: unknown;
    success: unknown;
    vendors: Vendor;
}

// export interface vendorEntity {
//     vendors: Array<object>
// }

@Module
export default class VendorModule extends VuexModule implements VendorInfo{
    errors = {};
    success = {};
    vendors = {} as Vendor;
    // vendors: vendorEntity[] = []

    /**
     * Get authentification success
     * @returns array
     */
    get getVendorSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getVendorErrors() {
        return this.errors;
    }

    /**
     * shows vendor list
     * @returns object
     */
    get getVendors(): object {
        return this.vendors;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched vendor list data are saved in this function
    [Mutations.SET_VENDOR_LIST](payload) {
        this.vendors = payload
    }

    @Action // fetch vendors
    [Actions.FETCH_VENDOR_LIST]() {
        return ApiService.get("/vendors")
            .then(({data}) => {
                this.context.commit(Mutations.SET_VENDOR_LIST, data.vendors);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store vendor
    [Actions.STORE_VENDOR](payload) {
        return ApiService.post("/vendors", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // update vendor
    [Actions.UPDATE_VENDOR](payload) {
        return ApiService.put("/vendors/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //delete vendor
    [Actions.DELETE_VENDOR](payload) {
        return ApiService.delete("/vendors/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            }).catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
