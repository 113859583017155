enum Actions {

    FETCH_SUBSCRIPTION_LIST = "fetchSubscriptionList",
    FETCH_LATEST_SUBSCRIPTION = "fetchLatestSubscription",
    FETCH_TODAY_SUBSCRIPTION_LIST = "fetchTodaySubscriptionList",
    FETCH_ADMIN_TODAY_SUBSCRIPTION_LIST = "fetchAdminTodaySubscriptionList",
    FETCH_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST = "fetchTodayGuestMealSubscriptionList",
    FETCH_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST = "fetchAdminTodayGuestMealSubscriptionList",
    FETCH_TODAY_DELIVERY_SUBSCRIPTION_LIST = "fetchTodayDeliverySubscriptionList",
    STORE_TODAY_SUBSCRIPTION = "storeTodaySubscription",
    STORE_TODAY_ALL_SUBSCRIBER_SUBSCRIPTION = "storeTodayAllSubscriberSubscription",
    STORE_TODAY_GUEST_MEAL_SUBSCRIPTION = "storeTodayGuestMealSubscription",
    STORE_TODAY_ALL_GUEST_MEAL_SUBSCRIPTION = "storeTodayAllGuestMealSubscription",

    FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST = "fetchSubscriberDailySubscriptionList",
    FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION = "fetchSubscriberDailySubscription",
    CANCEL_SUBSCRIPTION = "cancelSubscription",
    START_SUBSCRIPTION = "startSubscription",

    ADMIN_TODAY_SUBSCRIPTION_EXCEL_DOWNLOAD = "adminTodaySubscriptionExcelDownload",
    KITCHEN_COUNTER_TODAY_SUBSCRIPTION_EXCEL_DOWNLOAD = "kitchenCounterTodaySubscriptionExcelDownload",
}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    SET_SUBSCRIPTION_LIST = "setSubscriptionList",
    SET_LATEST_SUBSCRIPTION = "setLatestSubscription",

    SET_TODAY_SUBSCRIPTION_LIST = "setTodaySubscription",
    SET_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST = "setTodayGuestMealSubscription",

    SET_TODAY_SUBSCRIPTION_IDS_LIST = "setTodaySubscriptionIds",
    SET_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST = "setTodayGuestMealSubscriptionIds",

    SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST = "setAdminTodayGuestMealSubscription",
    SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST = "setAdminTodayGuestMealSubscriptionIds",
    SET_ADMIN_TODAY_SUBSCRIPTION_LIST = "setAdminTodaySubscription",
    SET_ADMIN_TODAY_SUBSCRIPTION_IDS_LIST = "setAdminTodaySubscriptionIds",

    SET_TODAY_DELIVERY_SUBSCRIPTION_LIST = "setTodayDeliverySubscription",

    SET_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST = "setSubscriberDailySubscriptionList",
    SET_SUBSCRIBER_DAILY_SUBSCRIPTION = "setSubscriberDailySubscription",
}

export {Actions, Mutations};