import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/MenuPageEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface MenuPages {
    menu_pages: Array<object>
}

export interface MenuPage {
    menu_page: any
}

export interface MenuPageInfo {
    errors: unknown;
    success: unknown;
    menu_pages: MenuPages;
    menu_page: MenuPage;
}

@Module
export default class MenuPageModule extends VuexModule implements MenuPageInfo{
    errors = {};
    success = {};
    menu_pages = {} as MenuPages;
    menu_page = {} as MenuPage;

    /**
     * Get authentification success
     * @returns array
     */
    get getMenuPageSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getMenuPageErrors() {
        return this.errors;
    }

    /**
     * shows permission list
     * @returns object
     */
    get getMenuPages(): object {
        return this.menu_pages;
    }

    /**
     * shows permission list
     * @returns object
     */
    get getMenuPage(): object {
        return this.menu_page;
    }

    @Mutation
    [Mutations.SET_MENU_PAGE_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_MENU_PAGE_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched permission list data are saved in this function
    [Mutations.SET_MENU_PAGE_LIST](payload) {
        this.menu_pages = payload
    }

    @Mutation // fetched permission list data are saved in this function
    [Mutations.SET_MENU_PAGE](payload) {
        this.menu_page = payload
    }

    @Action // fetch menu_pages
    [Actions.FETCH_MENU_PAGE_LIST]() {
        return ApiService.get("/menu-pages")
            .then(({data}) => {
                this.context.commit(Mutations.SET_MENU_PAGE_LIST, data.menu_pages);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_ERROR, response.data.errors);
            });
    }

    @Action // fetch menu_page
    [Actions.FETCH_MENU_PAGE](payload) {
        return ApiService.get("/menu-pages/"  + payload + '/edit')
            .then(({data}) => {
                this.context.commit(Mutations.SET_MENU_PAGE, data.menu_page);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_ERROR, response.data.errors);
            });
    }

    @Action // store menu_page
    [Actions.STORE_MENU_PAGE](payload) {
        return ApiService.post("/menu-pages", payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_ERROR, response.data.errors);
            });
    }

    @Action // update menu_page
    [Actions.UPDATE_MENU_PAGE](payload) {
        return ApiService.put("/menu-pages/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_ERROR, response.data.errors);
            });
    }

    @Action //delete menu_page
    [Actions.DELETE_MENU_PAGE](payload) {
        return ApiService.delete("/menu-pages/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_ERROR, response.data.errors);
            });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
