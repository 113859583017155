import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface InventoryHistoryData {
    inventory_history: object,
}

@Module
export default class InventoryHistoryModule extends VuexModule implements InventoryHistoryData {

    inventory_history = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getInventoryHistory(): object {
        return this.inventory_history;
    }

    @Mutation // setStockHistory
    [Mutations.SET_STOCK_HISTORY](payload) {
        this.inventory_history = payload
    }

    @Action // fetchInventoryHistory
    [Actions.FETCH_INVENTORY_HISTORY]($payload) {
        return ApiService.post("/report/inventory-history", $payload).then(({data}) => {
            this.context.commit(Mutations.SET_STOCK_HISTORY, data.inventory_history);
        }).catch(({response}) => {
            console.log('InventoryHistoryModule [FETCH_INVENTORY_HISTORY]', response)
            toast('error', response.data)
        });
    }

    @Action // exportInventoryHistory
    [Actions.EXPORT_INVENTORY_HISTORY](payload) {
        const date = moment(payload.date).format('YYYY-MM-DD')

        return ApiService.pdfDownloadPost("/report/inventory-history/export", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', date + '_' + payload.name +'_InventoryHistory.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('InventoryHistoryModule [EXPORT_INVENTORY_HISTORY]', response)
            toast('error', response.data)
        });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
