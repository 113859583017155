import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface AnnualLeaveDate {
    annual_leave_dates: Array<object>
}

export interface AnnualLeaveDateInfo {
    errors: unknown;
    success: unknown;
    annual_leave_dates: AnnualLeaveDate;
}

@Module
export default class AnnualLeaveDateModule extends VuexModule implements AnnualLeaveDateInfo{
    errors = {};
    success = {};
    annual_leave_dates = {} as AnnualLeaveDate;

    /**
     * Get authentification success
     * @returns array
     */
    get getAnnualLeaveDateSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getAnnualLeaveDateErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAnnualLeaveDates(): object {
        return this.annual_leave_dates;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched annual leave type list data are saved in this function
    [Mutations.SET_ANNUAL_LEAVE_DATE_LIST](payload) {
        this.annual_leave_dates = payload
    }

    @Action // fetch annual leave dates
    [Actions.FETCH_ANNUAL_LEAVE_DATE_LIST]() {
        return ApiService.get("/annual-leave-dates")
            .then(({data}) => {
                this.context.commit(Mutations.SET_ANNUAL_LEAVE_DATE_LIST, data.annual_leave_dates);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // store annual leave type
    [Actions.STORE_ANNUAL_LEAVE_DATE](payload) {
        return ApiService.post("/annual-leave-dates", payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // update annual leave type
    [Actions.UPDATE_ANNUAL_LEAVE_DATE](payload) {
        return ApiService.put("/annual-leave-dates/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action //delete annual leave type
    [Actions.DELETE_ANNUAL_LEAVE_DATE](payload) {
        return ApiService.delete("/annual-leave-dates/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}