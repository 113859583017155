enum Actions {

    SUBSCRIBER_CAMPAIGN_MEAL_GET_CODES = "subscriberCampaignMealGetCodes",
    FETCH_SUBSCRIBER_CAMPAIGN_MEAL_LIST = "fetchSubscriberCampaignMealList",
    FETCH_SUBSCRIBER_CAMPAIGN_MEAL = "fetchSubscriberCampaignMeal",
    STORE_SUBSCRIBER_CAMPAIGN_MEAL = "storeSubscriberCampaignMeal",
    UPDATE_SUBSCRIBER_CAMPAIGN_MEAL = "updateSubscriberCampaignMeal",
    DELETE_SUBSCRIBER_CAMPAIGN_MEAL = "deleteSubscriberCampaignMeal",

    FETCH_ADMIN_CAMPAIGN_MEAL_LIST = "fetchAdminSubscriberCampaignList",
    ADMIN_APPROVED_SUBSCRIBER_CAMPAIGN = "adminApprovedSubscriberCampaign",
    ADMIN_REJECTED_SUBSCRIBER_CAMPAIGN = "adminRejectSubscriberCampaign",

    FETCH_KITCHEN_MANAGER_CAMPAIGN_MEAL_LIST = "fetchKitchenManagerCampaignMealList",
    KITCHEN_MANAGER_APPROVED_SUBSCRIBER_CAMPAIGN = "kitchenManagerApprovedSubscriberCampaign",
    KITCHEN_MANAGER_REJECTED_SUBSCRIBER_CAMPAIGN = "kitchenManagerRejectSubscriberCampaign",

    EXPORT_CAMPAIGN_INVOICE = 'exportCampaignInvoice'
}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    SET_SUBSCRIBER_CAMPAIGN_MEAL_DEPARTMENT = "setSubscriberCampaignMealDepartment",
    SET_SUBSCRIBER_CAMPAIGN_MEAL_FOOD_ITEM = "setSubscriberCampaignMealFoodItem",
    SET_SUBSCRIBER_CAMPAIGN_MEAL = "setSubscriberCampaignMeal",
    SET_SUBSCRIBER_CAMPAIGN_MEAL_LIST = "setSubscriberCampaignMealList",
    SET_KITCHEN_MANAGER_CAMPAIGN_MEAL_LIST = "setKitchenManagerCampaignMealList",
    SET_ADMIN_CAMPAIGN_MEAL_LIST = "setAdminCampaignMealList",
}

export {Actions, Mutations};
