import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Location {
    locations: Array<object>
}

export interface LocationInfo {
    errors: unknown;
    success: unknown;
    locations: Location;
}

@Module
export default class LocationModule extends VuexModule implements LocationInfo{
    errors = {};
    success = {};
    locations = {} as Location;

    /**
     * Get authentification success
     * @returns array
     */
    get getLocationSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getLocationErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getLocations(): object {
        return this.locations;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched location list data are saved in this function
    [Mutations.SET_LOCATION_LIST](payload) {
        this.locations = payload
    }

    @Mutation // fetched location list data are saved in this function
    [Mutations.SET_LOCATION_LIST_FOR_REG](payload) {
        this.locations = payload
    }

    @Action // fetch locations
    [Actions.FETCH_LOCATION_LIST]() {
        return ApiService.get("/locations")
            .then(({data}) => {
                this.context.commit(Mutations.SET_LOCATION_LIST, data.locations);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch locations for registration
    [Actions.FETCH_LOCATION_LIST_FOR_REG]() {
        return ApiService.get("/reg-locations")
            .then(({data}) => {
                this.context.commit(Mutations.SET_LOCATION_LIST_FOR_REG, data.locations);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store location
    [Actions.STORE_LOCATION](payload) {
        return ApiService.post("/locations", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // update location
    [Actions.UPDATE_LOCATION](payload) {
        return ApiService.put("/locations/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //delete location
    [Actions.DELETE_LOCATION](payload) {
        return ApiService.delete("/locations/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}