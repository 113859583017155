import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ChatEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface ChatUsers {
    chat_users: Array<object>
}
export interface ChatMessages {
    messages: Array<object>
}

export interface ChatInfo {
    errors: unknown;
    success: unknown;
    chat_users: ChatUsers;
    messages: ChatMessages;
}

@Module
export default class ChatModule extends VuexModule implements ChatInfo{
    errors = {};
    success = {};
    chat_users = {} as ChatUsers;
    messages = {} as ChatMessages;

    /**
     * shows user type list
     * @returns object
     */
    get getChatUsers(): object {
        return this.chat_users;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getChatMessages(): object {
        return this.messages;
    }

    @Mutation
    [Mutations.SET_CHAT_USERS_LIST](payload) {
        this.chat_users = payload
    }

    @Mutation
    [Mutations.SET_CHAT_MESSAGES](payload) {
        this.messages = payload
    }

    @Action // fetch users
    [Actions.FETCH_CHAT_USERS_LIST]() {
        return ApiService.get("/chat/users")
            .then(({data}) => {
                this.context.commit(Mutations.SET_CHAT_USERS_LIST, data.users);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch messages
    [Actions.FETCH_CHAT_MESSAGES](payload) {
        return ApiService.post("/chat/messages", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_CHAT_MESSAGES, data.messages);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch messages
    [Actions.STORE_CHAT_MESSAGE](payload) {
        return ApiService.post("/chat/message/store", payload)
            .then(({data}) => {
                console.log(data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}