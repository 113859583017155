enum Actions {
    FETCH_ITEMS = "fetchItems",

    FETCH_PURCHASE_REQUEST_LIST = 'fetchPurchaseRequestList',
    CREATE_PURCHASE_REQUEST = 'createPurchaseRequest',
    EDIT_PURCHASE_REQUEST = 'editPurchaseRequest',
    SHOW_PURCHASE_REQUEST = 'showPurchaseRequest',
    UPDATE_PURCHASE_REQUEST = "updatePurchaseRequest",
    DELETE_PURCHASE_REQUEST = "deletePurchaseRequest",

    EXPORT_PURCHASE_REQUEST = "exportPurchaseRequest",
    EXPORT_EXCEL_PURCHASE_REQUEST = "exportExcelPurchaseRequest",

    ADMIN_APPROVED_PURCHASE_REQUEST = "adminApprovedPurchaseRequest",
    ADMIN_REJECTED_PURCHASE_REQUEST = "adminRejectedPurchaseRequest",

    GET_PURCHASE_REQUEST = "getPurchaseRequest",
    GET_PURCHASE_REQUEST_ITEMS = "getPurchaseRequestItems",

    FETCH_PURCHASE_ORDER_LIST = 'fetchPurchaseOrderList',
    CREATE_PURCHASE_ORDER = 'createPurchaseOrder',
    EDIT_PURCHASE_ORDER = 'editPurchaseOrder',
    SHOW_PURCHASE_ORDER = 'showPurchaseOrder',
    UPDATE_PURCHASE_ORDER = "updatePurchaseOrder",
    DELETE_PURCHASE_ORDER = "deletePurchaseOrder",

    EXPORT_PURCHASE_ORDER = 'exportPurchaseOrder',
    EXPORT_EXCEL_PURCHASE_ORDER = 'exportExcelPurchaseOrder',


    FETCH_VENDOR_LIST = "fetchVendorList",
    PR_SEND_TO_VENDOR_MAIL = "prSendToVendorMail",

    FETCH_PURCHASE_ORDER_INBOUND_ITEMS = 'fetchPurchaseOrderInboundItems',
    UPDATE_PURCHASE_ORDER_INBOUND = 'updatePurchaseOrderInbound',
}

enum Mutations {
    SET_ITEMS = "setItems",
    SET_PURCHASE_REQUEST = 'setPurchaseRequest',
    SET_PURCHASE_REQUEST_LIST = 'setPurchaseRequestList',
    SET_PURCHASE_REQUEST_DETAIL = 'setPurchaseRequestDetail',
    SET_PURCHASE_REQUEST_ITEM_DETAIL = 'setPurchaseRequestItemDetail',

    SET_PURCHASE_ORDER = 'setPurchaseOrder',
    SET_PURCHASE_ORDER_LIST = 'setPurchaseOrderList',

    SET_PURCHASE_ORDER_INBOUND_ITEMS = 'setPurchaseOrderItems'
}

export {Actions, Mutations};
