enum Actions {
    // Admin Module
    FETCH_ADMIN_ACCOUNT_LIST = "fetchAdminAccountList",
    FETCH_ADMIN_ACCOUNT_LIST_DOWNLOAD_PDF = "fetchAdminAccountListDownloadPdf",
    FETCH_ADMIN_ACCOUNT_LIST_DOWNLOAD_EXCEL = "fetchAdminAccountListDownloadExcel",
    FETCH_ADMIN_ACCOUNT = "fetchAdminAccount",

    // Subscriber module
    FETCH_SUBSCRIBER_ACCOUNT_LIST = "fetchSubscriberAccountList",
    FETCH_SUBSCRIBER_ACCOUNT_LIST_DOWNLOAD = "fetchSubscriberAccountListDownload",
    FETCH_SUBSCRIBER_ACCOUNT = "fetchSubscriberAccount",
}

enum Mutations {

    // Admin Module
    SET_ADMIN_ACCOUNT_LIST = "setAdminAccountList",
    SET_ADMIN_ACCOUNT_LIST_DOWNLOAD = "setAdminAccountListDownload",
    SET_ADMIN_ACCOUNT = "setAdminAccount",
    SET_ADMIN_ACCOUNT_ERROR = "setAdminAccountError",
    SET_ADMIN_ACCOUNT_SUCCESS = "setAdminAccountSuccess",

    // Subscriber module
    SET_SUBSCRIBER_ACCOUNT_LIST = "setSubscriberAccountList",
    SET_SUBSCRIBER_ACCOUNT = "setSubscriberAccount",
    SET_SUBSCRIBER_ACCOUNT_LIST_DOWNLOAD_PDF = "setSubscriberAccountListDownloadPdf",
}

export {Actions, Mutations};
