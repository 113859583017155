enum Actions {

    FETCH_GENERIC_CONSUMPTION_DATA = "fetchGenericConsumptionData",
    FILTER_BY_KITCHEN_STATION = "filterByKitchenStation",

    ADD_CONSUMPTION_ITEMS = "addConsumptionItems",
    FETCH_CONSUMPTION_HISTORY = "fetchConsumptionHistory",
    EXPORT_CONSUMPTION_HISTORY = "exportConsumptionHistory",
    DELETE_CONSUMPTION = 'DeleteConsumption',

}

enum Mutations {

    SET_KITCHEN_ITEMS = "setKitchenItems",

    SET_CONSUMPTION_HISTORY = "setConsumptionHistory",

}

export {Actions, Mutations};
