import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/AltenativeScheduleEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";
import router from "@/router";

export interface GuestMeals {
    guest_meals: Array<object>
}

export interface Locations {
    locations: Array<object>
}

export interface OriginalLocations {
    original_locations: Array<object>
}

export interface AlternativeScheduleInfo {
    errors: unknown;
    success: unknown;
    guest_meals: GuestMeals;
    locations: Locations;
    original_locations: OriginalLocations;
}

@Module
export default class AlternativeScheduleModule extends VuexModule implements AlternativeScheduleInfo {
    errors = {};
    success = {};
    guest_meals = {} as GuestMeals;
    locations = {} as Locations;
    original_locations = {} as OriginalLocations;

    /**
     * Get authentification success
     * @returns array
     */
    get getAlternativeScheduleSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getAlternativeScheduleErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAlternativeMealGuest(): object {
        return this.guest_meals;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAlternativeLocation(): object {
        return this.locations;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getOriginalLocation(): object {
        return this.original_locations;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched guest meals
    [Mutations.SET_GUEST_MEAL_LIST](payload) {
        this.guest_meals = payload
    }

    @Mutation // fetched locations
    [Mutations.SET_ALTERNATIVE_LOCATION_LIST](payload) {
        this.locations = payload
    }

    @Mutation // fetched original locations
    [Mutations.SET_LOCATION_LIST_FOR_ALTERNATIVE_LOCATION](payload) {
        this.original_locations = payload
    }

    @Action // fetch guest meals
    [Actions.FETCH_GUEST_MEAL_LIST]() {
        return ApiService.get("/subscription/alternative-schedule/guest-meals")
            .then(({data}) => {
                this.context.commit(Mutations.SET_GUEST_MEAL_LIST, data.alternative_guest_meals);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch locations
    [Actions.FETCH_ALTERNATIVE_LOCATION_LIST]() {
        return ApiService.get("/subscription/alternative-schedule/locations")
            .then(({data}) => {
                this.context.commit(Mutations.SET_ALTERNATIVE_LOCATION_LIST, data.alternative_locations);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store guest meal
    [Actions.STORE_GUEST_MEAL](payload) {
        return ApiService.post("/subscription/alternative-schedule/guest-meal/store", payload)
            .then(({data}) => {
                toast('success', data)
                router.push({name: "subscriber-alternative-guest-meal-list"});
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store location
    [Actions.STORE_ALTERNATIVE_LOCATION](payload) {
        return ApiService.post("/subscription/alternative-schedule/location/store", payload)
            .then(({data}) => {
                toast('success', data)
                router.push({name: "subscriber-alternative-location-list"});
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action  // delete guest meal
    [Actions.DELETE_GUEST_MEAL](payload) {
        return ApiService.delete("/subscription/alternative-schedule/guest-meal/delete/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action  // delete location
    [Actions.DELETE_ALTERNATIVE_LOCATION](payload) {
        return ApiService.delete("/subscription/alternative-schedule/location/delete/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch locations
    [Actions.FETCH_LOCATION_LIST_FOR_ALTERNATIVE_LOCATION]() {
        return ApiService.get("/subscription/location/list")
            .then(({data}) => {
                this.context.commit(Mutations.SET_LOCATION_LIST_FOR_ALTERNATIVE_LOCATION, data.locations);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}


// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
