import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/PermissionEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Permission {
    permissions: Array<object>
}

export interface PermissionInfo {
    errors: unknown;
    success: unknown;
    permissions: Permission;
}

@Module
export default class PermissionModule extends VuexModule implements PermissionInfo{
    errors = {};
    success = {};
    permissions = {} as Permission;

    /**
     * Get authentification success
     * @returns array
     */
    get getPermissionSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getPermissionErrors() {
        return this.errors;
    }

    /**
     * shows permission list
     * @returns object
     */
    get getPermissions(): object {
        return this.permissions;
    }

    @Mutation
    [Mutations.SET_PERMISSION_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_PERMISSION_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched permission list data are saved in this function
    [Mutations.SET_PERMISSION_LIST](payload) {
        this.permissions = payload
    }

    @Action // fetch permissions
    [Actions.FETCH_PERMISSION_LIST]() {
        return ApiService.get("/permissions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_PERMISSION_LIST, data.permissions);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_PERMISSION_ERROR, response.data.errors);
            });
    }

    @Action // store permission
    [Actions.STORE_PERMISSION](payload) {
        return ApiService.post("/permissions", payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_PERMISSION_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_PERMISSION_ERROR, response.data.errors);
            });
    }

    @Action // update permission
    [Actions.UPDATE_PERMISSION](payload) {
        return ApiService.put("/permissions/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_PERMISSION_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_PERMISSION_ERROR, response.data.errors);
            });
    }

    @Action //delete permission
    [Actions.DELETE_PERMISSION](payload) {
        return ApiService.delete("/permissions/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_PERMISSION_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_PERMISSION_ERROR, response.data.errors);
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}