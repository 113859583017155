enum Actions {
    STORE_SUBSCRIBER_IMPORT_LIST = "storeSubscriberImportList",

}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",
}

export {Actions, Mutations};