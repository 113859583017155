enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",

    LOGIN = "login",
    LOGOUT = "logout",

    REGISTER = "register",
    GET_REG_GENERIC_DATA = "getRegGenericData",

    CANCEL_SUBSCRIPTION = "cancelSubscription",
    RENEWAL_SUBSCRIPTION = "renewalSubscription",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    UPDATE_PASSWORD = "updatePassword",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

    // For Registration
    FETCH_CODES_FOR_REG = "fetchCodesForReg",
}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ROLES = "setRoles",
    SET_PERMISSIONS = "setPermissions",
    SET_MENU_PERMISSIONS = "setMenuPermissions",
    SET_ERROR = "setError",
    SET_RESPONSE_STATUS = 'setResponseStatus',
    SET_SUCCESS = "setSuccess",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

    // For Registration
    SET_REG_LOCATION = "setRegLocation",
    SET_REG_DEPARTMENT = "setRegDepartment",
    SET_REG_RELIGION = "setRegReligion",
}

export {Actions, Mutations};
