import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/PurchaseEnums";
import {Mutations as SettingMutations} from "@/store/enums/SettingEnums"
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import router from "@/router";

export interface PurchaseRequestData {
    items: object,
    purchaseRequest: object,
    purchaseRequestList: object,
    purchaseRequestDetail: object,
}

@Module
export default class PurchaseRequestModule extends VuexModule implements PurchaseRequestData {

    items = {};
    purchaseRequest = {};
    purchaseRequestList = {};
    purchaseRequestDetail = {};

    /**
     * shows item list
     * @returns object
     */
    get getPRItems(): object {
        return this.items;
    }

    /**
     * shows purchase request detail
     * @returns object
     */
    get getPurchaseRequest(): object {
        return this.purchaseRequest;
    }

    /**
     * shows purchase requests list
     * @returns object
     */
    get getPurchaseRequests(): object {
        return this.purchaseRequestList;
    }

    /**
     * shows purchase request details
     * @returns object
     */
    get getPurchaseRequestDetail(): object {
        return this.purchaseRequestDetail;
    }

    @Mutation // setItems
    [Mutations.SET_ITEMS](payload) {
        this.items = payload
    }

    @Mutation // setPurchaseRequest
    [Mutations.SET_PURCHASE_REQUEST](payload) {
        this.purchaseRequest = payload
    }

    @Mutation // setPurchaseRequestDetail
    [Mutations.SET_PURCHASE_REQUEST_DETAIL](payload) {
        this.purchaseRequestDetail = payload
    }

    @Mutation // set purchase request list
    [Mutations.SET_PURCHASE_REQUEST_LIST](payload) {
        this.purchaseRequestList = payload
    }

    @Action // fetchItems
    [Actions.FETCH_ITEMS]() {
        return ApiService.get("/purchase-request/all-info")
            .then(({data}) => {
                this.context.commit(Mutations.SET_ITEMS, data.items);
                this.context.commit(SettingMutations.SET_KITCHEN_STATION_LIST, data.kitchens);
            })
            .catch(({response}) => {
                console.log('PurchaseRequestModule [FETCH_ITEM_LIST]', response)
                toast('error', response.data)
            });
    }

    @Action // fetchPurchaseRequestList
    [Actions.FETCH_PURCHASE_REQUEST_LIST](payload) {
        return ApiService.post("/purchase-request", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_REQUEST_LIST, data.purchase_requests);
            })
            .catch(({response}) => {
                console.log('PurchaseRequestModule [FETCH_PURCHASE_REQUEST_LIST]', response)
                toast('error', response.data)
            });
    }

    @Action // createPurchaseRequest
    [Actions.CREATE_PURCHASE_REQUEST](payload) {
        return ApiService.post("/purchase-request/store", payload)
            .then(({data}) => {
                toast('success', data)
                router.push({name: 'purchase-request-list'})
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [CREATE_PURCHASE_REQUEST]', response.data.message)
                toast('error', response.data)
            });

    }

    @Action // editPurchaseRequest
    [Actions.EDIT_PURCHASE_REQUEST](payload) {
        return ApiService.get("/purchase-request/edit/" + payload.id,)
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_REQUEST_DETAIL, data.items);
                this.context.commit(SettingMutations.SET_KITCHEN_STATION_LIST, data.kitchens);
                this.context.commit(Mutations.SET_PURCHASE_REQUEST, data.purchase_request);
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [EDIT_PURCHASE_REQUEST]', response)
                toast('error', response.data)
            });
    }

    @Action // showPurchaseRequest
    [Actions.SHOW_PURCHASE_REQUEST](payload) {
        return ApiService.get("/purchase-request/show/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_PURCHASE_REQUEST, data.purchase_request);
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [SHOW_PURCHASE_REQUEST]', response)
                toast('error', response.data)
            });
    }

    @Action // updatePurchaseRequest
    [Actions.UPDATE_PURCHASE_REQUEST](payload) {
        return ApiService.put("/purchase-request/update/" + payload.purchaseRequestId, payload)
            .then(({data}) => {
                toast('success', data)
                router.push({name: 'purchase-request-list'})
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [UPDATE_PURCHASE_REQUEST]', response.data.message)
                toast('error', response.data)
            });

    }

    @Action // deletePurchaseRequest
    [Actions.DELETE_PURCHASE_REQUEST](payload) {
        return ApiService.delete("/purchase-request/delete/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [DELETE_PURCHASE_REQUEST]', response.data.message)
                toast('error', response.data)
            });

    }

    @Action // exportPurchaseRequest
    [Actions.EXPORT_PURCHASE_REQUEST](payload) {
        return ApiService.pdfDownloadPost("/purchase-request/export-pdf", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'purchase_request.pdf')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [EXPORT_PURCHASE_REQUEST]', response)
                toast('error', response.data)
            });
    }

    @Action // exportExcelPurchaseRequest
    [Actions.EXPORT_EXCEL_PURCHASE_REQUEST](payload) {
        return ApiService.pdfDownloadPost("/purchase-request/export-excel", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', payload.purchaseRequestName + '.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                console.error('PurchaseRequestModule [EXPORT_EXCEL_PURCHASE_REQUEST]', response)
                toast('error', response.data)
            });
    }

    @Action // admin approved purchase request
    [Actions.ADMIN_APPROVED_PURCHASE_REQUEST](payload) {
        return ApiService.post("/admin/purchase-request/approved", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // admin reject purchase request
    [Actions.ADMIN_REJECTED_PURCHASE_REQUEST](payload) {
        return ApiService.post("/admin/purchase-request/rejected", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // PR send to vendor mail
    [Actions.PR_SEND_TO_VENDOR_MAIL](payload) {
        return ApiService.post("/admin/purchase-request/send-to-vendor", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
