const API_TOKEN_KEY = "id_token" as string;
const MENU_KEY = "id_menu" as any;
//const RELOAD_KEY = "id_reload" as any;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(API_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(API_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(API_TOKEN_KEY);
};

/**
 * @description get menu form localStorage
 */
export const getMenu = (): any | null => {
  return window.localStorage.getItem(MENU_KEY);
};

/**
 * @description save menu into localStorage
 * @param menu any
 */
export const saveMenu = (menu: any): void => {
  window.localStorage.setItem(MENU_KEY, menu);
};

/**
 * @description remove menu form localStorage
 */
export const destroyMenu = (): void => {
  window.localStorage.removeItem(MENU_KEY);
};

// /**
//  * @description save menu into localStorage
//  * @param reloaded any
//  */
// export const saveReload = (reloaded: any): void => {
//   window.localStorage.setItem(RELOAD_KEY, reloaded);
// };

export default { getToken, saveToken, destroyToken, getMenu, saveMenu, destroyMenu };
