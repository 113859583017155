import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/KitchenManagerSubscriptionEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

export interface SubscriptionCounters {
    subscription_counters: Array<object>
}
export interface TotalSubscriptionCounters {
    total_subscription_counter: Array<object>
}

export interface SubscriptionInfo {
    errors: unknown;
    success: unknown;
    subscription_counters: SubscriptionCounters;
    total_subscription_counter: TotalSubscriptionCounters;
}

@Module
export default class SubscriptionModule extends VuexModule implements SubscriptionInfo{
    errors = {};
    success = {};
    subscription_counters = {} as SubscriptionCounters;
    total_subscription_counter = {} as TotalSubscriptionCounters;

    /**
     * Get authentification success
     * @returns array
     */
    get getKitchenManagerSubscriptionSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getKitchenManagerSubscriptionErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getKitchenManagerSubscriptionCounters(): object {
        return this.subscription_counters;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getKitchenManagerTotalSubscriptionCounters(): object {
        return this.total_subscription_counter;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_KITCHEN_MANAGER_SUBSCRIPTION_COUNTER_LIST](payload) {
        this.subscription_counters = payload
    }

    @Mutation
    [Mutations.SET_KITCHEN_MANAGER_SUBSCRIPTION_TOTAL_COUNTER](payload) {
        this.total_subscription_counter = payload
    }

    @Action // fetch subscription counter
    [Actions.FETCH_KITCHEN_MANAGER_SUBSCRIPTION_COUNTER_LIST](payload) {
        return ApiService.post("/kitchen-manager/subscription-counter", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_KITCHEN_MANAGER_SUBSCRIPTION_COUNTER_LIST, data.subscription_counters);
                this.context.commit(Mutations.SET_KITCHEN_MANAGER_SUBSCRIPTION_TOTAL_COUNTER, data.total_subscription_counter);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

}
