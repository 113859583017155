import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/DashboardEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import router from "@/router";
import {array} from "yup"; // importing toast library

export interface DashboardData {
    activeSubscribers: null | number;
    activeDriver: null | number;
    activeSupportStaff: null | number;

    totalSubscriberMeal: null | number;
    totalSubscriberConsumptionMeal: null | number;

    religionCount: object,
    mealCountPerLocation: object,

    dailyFoodItem: object,
    foodItemsList: object,
    feastList: object,
    upcoming_campaign_meals: object,
    monthly_subscriber_and_unsubscribe_chart: object,
    monthly_purchase_orders_chart: object,
}

@Module
export default class DashboardModule extends VuexModule implements DashboardData {

    activeSubscribers = null;
    activeDriver = null;
    activeSupportStaff = null;

    totalSubscriberMeal = null;
    totalSubscriberConsumptionMeal = null;

    religionCount = {};
    mealCountPerLocation = {};

    dailyFoodItem = {};
    foodItemsList = {};
    feastList = {};
    upcoming_campaign_meals = {};
    monthly_subscriber_and_unsubscribe_chart = {};
    monthly_purchase_orders_chart = {};

    /**
     * shows active user data
     * @returns object
     */
    get getActiveUser() {
        return {
            'driver': this.activeDriver,
            'subscribers': this.activeSubscribers,
            'supportStaff': this.activeSupportStaff,
        };
    }

    /**
     * shows daily meal status
     * @returns object
     */
    get getDailySubscriberMealStatus() {
        return {
            'totalMeal': this.totalSubscriberMeal ? this.totalSubscriberMeal : 0,
            'consume': this.totalSubscriberConsumptionMeal ? this.totalSubscriberConsumptionMeal : 0,
        }
    }

    /**
     * shows daily food items data
     * @returns object
     */
    get getDailyFoodItemList(): object {
        return this.dailyFoodItem;
    }

    /**
     * shows feast list
     * @returns object
     */
    get getFeastList(): object {
        return this.feastList;
    }

    /**
     * shows food items list
     * @returns object
     */
    get getFoodItemsList(): object {
        return this.foodItemsList;
    }

    /**
     * shows religion count
     * @returns object
     */
    get getReligionCount(): object {
        return this.religionCount;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getMealCountPerLocation(): object {
        return this.mealCountPerLocation;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getUpcomingCampaignMeals(): object {
        return this.upcoming_campaign_meals;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getMonthlySubscriberAndUnsubscribeChart(): object {
        return this.monthly_subscriber_and_unsubscribe_chart;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getMonthlyPurchaseRequestChart(): object {
        return this.monthly_purchase_orders_chart;
    }


    @Mutation // setDailyFoodItemList
    [Mutations.SET_DASHBOARD_DATA](payload) {

        // active user data
        this.activeSubscribers = payload.active_subscribers;
        this.activeDriver = payload.active_driver;
        this.activeSupportStaff = payload.active_support_staff;

        // daily meal status
        this.totalSubscriberMeal = payload.today_delivery_and_consumption[0].total_subscriber_meal;
        this.totalSubscriberConsumptionMeal = payload.today_delivery_and_consumption[0].total_subscriber_meal_consumption;
    }

    @Mutation // setDailyFoodItemList
    [Mutations.SET_DAILY_FOOD_ITEM_LIST](payload) {
        this.dailyFoodItem = payload
    }

    @Mutation // SetFeastList
    [Mutations.SET_FEAST_LIST](payload) {
        this.feastList = payload
    }

    @Mutation // setFoodItemList
    [Mutations.SET_FOOD_ITEM_LIST](payload) {
        this.foodItemsList = payload
    }

    @Mutation // setFoodItemList
    [Mutations.SET_RELIGION_COUNT](payload) {
        this.religionCount = payload
    }

    @Mutation // setFoodItemList
    [Mutations.SET_MEAL_COUNT_PER_LOCATION](payload) {
        this.mealCountPerLocation = payload
    }

    @Mutation // setUpcomingCampaignMeal
    [Mutations.SET_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD](payload) {
        this.upcoming_campaign_meals = payload
    }
    @Mutation // setUpcomingCampaignMeal
    [Mutations.SET_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART](payload) {
        this.monthly_subscriber_and_unsubscribe_chart = payload
    }

    @Mutation // setPurchaseRequestChart
    [Mutations.SET_MONTHLY_PURCHASE_REQUEST_CHART](payload) {
        this.monthly_purchase_orders_chart = payload
    }

    @Action // fetchDashboardData
    [Actions.FETCH_DASHBOARD_DATA](payload) {
        return ApiService.get("/dashboard", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_DASHBOARD_DATA, data);
                this.context.commit(Mutations.SET_DAILY_FOOD_ITEM_LIST, data.daily_food_item);
                this.context.commit(Mutations.SET_FOOD_ITEM_LIST, data.food_item_lists);
                this.context.commit(Mutations.SET_FEAST_LIST, data.feast_list);
                this.context.commit(Mutations.SET_RELIGION_COUNT, data.religion[0]);
                this.context.commit(Mutations.SET_MEAL_COUNT_PER_LOCATION, data.meal_count_per_location);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetchUpcomingCampaignMeal
    [Actions.FETCH_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD](payload) {
        return ApiService.get("/upcoming-campaign-meal/dashboard", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD, data.upcoming_campaign_meals);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetchMonthlySubscriberAndUnsubscribeChart
    [Actions.FETCH_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART](payload) {
        return ApiService.post("/monthly-subscriber-and-unsubscribe/chart", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART, data.subscribers);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetchMonthlyPurchaseRequestChart
    [Actions.FETCH_MONTHLY_PURCHASE_REQUEST_CHART](payload) {
        return ApiService.post("/monthly-purchase-request/chart", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_MONTHLY_PURCHASE_REQUEST_CHART, data.monthly_purchase_orders_chart);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}

