import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/UserEnums";
import {Mutations as PermissionEnumsMutations} from "@/store/enums/PermissionEnums";
import {Mutations as SettingEnumsMutations} from "@/store/enums/SettingEnums";

import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";
import router from "@/router";

export interface Users {
    users: Array<object>
}

export interface HrUsers {
    hr_users: Array<object>
}

export interface UserTypes {
    user_types: Array<object>
}

export interface User {
    user: any
}
export interface UserDetails {
    user_details: any
}

export interface UserInfo {
    errors: unknown;
    success: unknown;
    users: Users;
    hr_users: HrUsers;
    user: User;
    user_types: UserTypes;
    user_details: UserDetails;
    religions: any
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
    errors = {};
    success = {};
    religions = {};
    users = {} as Users;
    hr_users = {} as HrUsers;
    user = {} as User;
    user_types = {} as UserTypes;
    user_details = {} as UserDetails;

    /**
     * Get authentification success
     * @returns array
     */
    get getUserSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getUserErrors() {
        return this.errors;
    }

    /**
     * Get authentification success
     * @returns array
     */
    get getUserProfileSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getUserProfileErrors() {
        return this.errors;
    }

    /**
     * shows user list
     * @returns object
     */
    get getUsers(): object {
        return this.users;
    }

    /**
     * shows user list
     * @returns object
     */
    get getHrUsers(): object {
        return this.hr_users;
    }

    /**
     * shows user list
     * @returns object
     */
    get getUserTypes(): object {
        return this.user_types;
    }

        /**
     * shows religion list
     * @returns object
     */
    get getReligions(): object {
        return this.religions;
    }

    /**
     * shows user list
     * @returns object
     */
    get getUser(): object {
        return this.user;
    }

    /**
     * shows user list
     * @returns object
     */
    get getUserDetails(): object {
        return this.user_details;
    }

    @Mutation
    [Mutations.SET_USER_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_USER_PROFILE_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_USER_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_USER_PROFILE_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched user type list
    [Mutations.SET_USER_TYPE_LIST](payload) {
        this.user_types = payload
    }

    @Mutation // fetched user list data are saved in this function
    [Mutations.SET_USER_LIST](payload) {
        this.users = payload
    }

    @Mutation // fetched user list data are saved in this function
    [Mutations.SET_USER_DETAILS](payload) {
        this.user_details = payload
    }

    @Mutation // fetched user list data are saved in this function
    [Mutations.SET_HR_USER_LIST](payload) {
        this.hr_users = payload
    }

    @Mutation // fetched user list data are saved in this function
    [Mutations.SET_USER](payload) {
        this.user = payload
    }

    @Mutation // fetched user list data are saved in this function
    [Mutations.SET_RELIGION_LIST](payload) {
        this.religions = payload
    }


    @Action // fetch user generic data
    [Actions.FETCH_USER_GENERIC_DATA]() {
        return ApiService.get("/users/user-generic-data")
            .then(({data}) => {
                this.context.commit(SettingEnumsMutations.SET_DEPARTMENT_LIST, data.departments);
                this.context.commit(SettingEnumsMutations.SET_KITCHEN_STATION_LIST, data.kitchen_stations);
                this.context.commit(SettingEnumsMutations.SET_LOCATION_LIST, data.locations);
                this.context.commit(Mutations.SET_RELIGION_LIST, data.religions);
                this.context.commit(SettingEnumsMutations.SET_PAYMENT_TYPE_LIST, data.payment_types);
                this.context.commit(PermissionEnumsMutations.SET_ROLE_LIST, data.roles);
                this.context.commit(Mutations.SET_USER_TYPE_LIST, data.user_types);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch user types
    [Actions.FETCH_USER_TYPE_LIST]() {
        return ApiService.get("/users/user-types")
            .then(({data}) => {
                this.context.commit(Mutations.SET_USER_TYPE_LIST, data.user_types);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch users
    [Actions.FETCH_USER_LIST](payload) {
        return ApiService.post("/admin/users", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_USER_LIST, data.users);
            })
            .catch(async ({response}) => {
                if (response.data.code == 403) {
                    await router.push('/404');
                }
                toast('error', response.data)
            });
    }

    @Action // fetch hr users
    [Actions.FETCH_HR_USER_LIST](payload) {
        return ApiService.post("/hr/users", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_HR_USER_LIST, data.users);
            })
            .catch(async ({response}) => {
                if (response.data.code == 403) {
                    await router.push('/404');
                }
                toast('error', response.data)
            });
    }

    @Action // fetch user
    [Actions.FETCH_USER](payload) {
        return ApiService.get("/users/edit/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_USER, data.user);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch user
    [Actions.DETAILS_USERS](payload) {
        return ApiService.get("/users/details/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_USER_DETAILS, data.user);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store user
    [Actions.STORE_USER](payload) {
        return ApiService.post("/users/store", payload)
            .then(async ({data}) => {
                toast('success', data)
                await router.push('/users');
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // update user
    [Actions.UPDATE_USER](payload) {
        return ApiService.withImageUpdate("/users/update/" + payload.id, payload.user_data)
            .then(async ({data}) => {
                toast('success', data)
                await router.push('/users');
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // update user profile
    [Actions.UPDATE_USER_PROFILE](payload) {
        return ApiService.withImageUpdate("/users/profile/update/" + payload.id, payload.user_data)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // update user hr brand
    [Actions.UPDATE_USER_HR_BRAND](payload) {
        return ApiService.put("/users/hr-brand/" + payload.id, payload).then(
            ({data}) => toast('success', data)
        ).catch(({response}) => toast('error', response.data));
    }

    @Action // update user payment type
    [Actions.UPDATE_USER_PAYMENT_TYPE](payload) {
        return ApiService.put("/users/payment-type/" + payload.id, payload)
            .then(({data}) => {
               if (data.success){
                   toast('success', data)
               }else {
                   toast('error', data)
               }

            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //delete user
    [Actions.DELETE_USER](payload) {
        return ApiService.delete("/users/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // update user password
    [Actions.UPDATE_USER_PASSWORD](payload) {
        return ApiService.post("/users/password/update", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // user list Excel download
    [Actions.DOWNLOAD_USER_EXCEL_LIST](payload) {
        return ApiService.pdfDownloadPost("/users/excel/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'users.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // D-lunch manual download
    [Actions.DOWNLOAD_D_LUNCH_MANUAL](payload) {
        return ApiService.pdfDownloadPost("/d-lunch-manual/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'd-lunch.pdf')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // update user password from admin
    [Actions.UPDATE_USER_PASSWORD_FROM_ADMIN](payload) {
        return ApiService.post("/admin/users/password/update", payload)
            .then(({data}) => {
                if (data.success){
                    toast('success', data)
                }else {
                    toast('error', data)
                }

            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
