enum Actions {
    // User Module
    FETCH_USER_LIST = "fetchUserList",
    FETCH_HR_USER_LIST = "fetchHrUserList",
    FETCH_USER_TYPE_LIST = "fetchUserTypeList",
    FETCH_USER_GENERIC_DATA = "userGenericData",
    FETCH_USER = "fetchUser",
    STORE_USER = "storeUser",
    UPDATE_USER = "updateUser",
    DELETE_USER = "deleteUser",
    DELETE_USERS = "deleteUsers",
    DETAILS_USERS = "detailsUsers",
    UPDATE_USER_PROFILE = "updateUserProfile",
    UPDATE_USER_HR_BRAND = "updateUserHrBrand",
    UPDATE_USER_PAYMENT_TYPE = "updateUserPaymentType",
    UPDATE_USER_PASSWORD = "updateUserPassword",
    DOWNLOAD_USER_EXCEL_LIST = "downloadUserExcelList",
    DOWNLOAD_D_LUNCH_MANUAL = "downloadDLunchManual",

    UPDATE_USER_PASSWORD_FROM_ADMIN = "updateUserPasswordFromAdmin",
}

enum Mutations {

    // User Module
    SET_USER_LIST = "setUserList",
    SET_USER_DETAILS = "setUserDetails",
    SET_HR_USER_LIST = "setHrUserList",
    SET_USER_TYPE_LIST = "setUserTypeList",
    SET_USER = "setUser",
    SET_USER_ERROR = "setUserError",
    SET_USER_SUCCESS = "setUserSuccess",
    SET_USER_PROFILE_ERROR = "setUserProfileError",
    SET_USER_PROFILE_SUCCESS = "setUserProfileSuccess",

    SET_RELIGION_LIST = "setReligionList"
}

export {Actions, Mutations};
