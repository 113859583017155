// import { AbilityBuilder, Ability } from '@casl/ability'
//
// const { can, cannot, build } = new AbilityBuilder(Ability)
//
// export default build()
//

import { Ability } from '@casl/ability'

export default new Ability()
