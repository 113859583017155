import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/CampaignMealEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface SubscriberCampaignMeals {
    subscriber_campaign_meals: Array<object>
}

export interface SubscriberCampaignMeal {
    subscriber_campaign_meal: Array<object>
}

export interface KitchenMangerCampaignMeals {
    kitchen_manager_campaign_meals: Array<object>
}

export interface AdminCampaignMeals {
    admin_campaign_meals: Array<object>
}

export interface CampaignMealDepartments {
    departments: Array<object>
}

export interface CampaignMealFoodItems {
    food_items: Array<object>
}

export interface CampaignMealInfo {
    errors: unknown;
    success: unknown;
    subscriber_campaign_meals: SubscriberCampaignMeals;
    subscriber_campaign_meal: SubscriberCampaignMeal;
    kitchen_manager_campaign_meals: KitchenMangerCampaignMeals;
    admin_campaign_meals: AdminCampaignMeals;
    departments: CampaignMealDepartments;
    food_items: CampaignMealFoodItems;
}

@Module
export default class CampaignMealModule extends VuexModule implements CampaignMealInfo {
    errors = {};
    success = {};
    subscriber_campaign_meals = {} as SubscriberCampaignMeals;
    subscriber_campaign_meal = {} as SubscriberCampaignMeal;
    kitchen_manager_campaign_meals = {} as KitchenMangerCampaignMeals;
    admin_campaign_meals = {} as AdminCampaignMeals;
    departments = {} as CampaignMealDepartments;
    food_items = {} as CampaignMealFoodItems;

    /**
     * Get authentification success
     * @returns array
     */
    get getCampaignMealSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getCampaignMealErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getCampaignMealDepartments(): object {
        return this.departments;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getCampaignMealFoodItems(): object {
        return this.food_items;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getSubscriberCampaignMeals(): object {
        return this.subscriber_campaign_meals;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriberCampaignMeal(): object {
        return this.subscriber_campaign_meal;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getKitchenManagerCampaignMeals(): object {
        return this.kitchen_manager_campaign_meals;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminCampaignMeals(): object {
        return this.admin_campaign_meals;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched campaign meal departments
    [Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL_DEPARTMENT](payload) {
        this.departments = payload
    }

    @Mutation // fetched campaign meal food items
    [Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL_FOOD_ITEM](payload) {
        this.food_items = payload
    }

    @Mutation // fetched campaign meal
    [Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL_LIST](payload) {
        this.subscriber_campaign_meals = payload
    }

    @Mutation // fetched campaign meal
    [Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL](payload) {
        this.subscriber_campaign_meal = payload
    }

    @Mutation // fetched kitchen manager campaign meal
    [Mutations.SET_KITCHEN_MANAGER_CAMPAIGN_MEAL_LIST](payload) {
        this.kitchen_manager_campaign_meals = payload
    }

    @Mutation // fetched admin campaign meal
    [Mutations.SET_ADMIN_CAMPAIGN_MEAL_LIST](payload) {
        this.admin_campaign_meals = payload
    }

    @Action // fetch SUBSCRIBER CAMPAIGN MEAL GET CODES
    [Actions.SUBSCRIBER_CAMPAIGN_MEAL_GET_CODES]() {
        return ApiService.get("/subscriber/campaign-meal/get-codes")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL_DEPARTMENT, data.departments);
                this.context.commit(Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL_FOOD_ITEM, data.food_items);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // fetch SUBSCRIBER CAMPAIGN MEAL
    [Actions.FETCH_SUBSCRIBER_CAMPAIGN_MEAL_LIST]() {
        return ApiService.get("/subscriber/campaign-meal/list")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL_LIST, data.subscriber_campaign_meals);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // fetch SUBSCRIBER CAMPAIGN MEAL
    [Actions.FETCH_SUBSCRIBER_CAMPAIGN_MEAL](payload) {
        return ApiService.get("/subscriber/campaign-meal/edit/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_CAMPAIGN_MEAL, data.subscriber_campaign_meal);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // fetch kitchen manager campaign meal
    [Actions.FETCH_KITCHEN_MANAGER_CAMPAIGN_MEAL_LIST]() {
        return ApiService.get("/kitchen-manager/campaign-meal/list")
            .then(({data}) => {
                this.context.commit(Mutations.SET_KITCHEN_MANAGER_CAMPAIGN_MEAL_LIST, data.kitchen_manager_campaign_meals);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // fetch admin campaign meal
    [Actions.FETCH_ADMIN_CAMPAIGN_MEAL_LIST]() {
        return ApiService.get("/admin/campaign-meal/list")
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_CAMPAIGN_MEAL_LIST, data.admin_campaign_meals);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // admin approved subscriber campaign meal
    [Actions.ADMIN_APPROVED_SUBSCRIBER_CAMPAIGN](payload) {
        return ApiService.post("/admin/campaign-meal/approved", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // admin reject subscriber campaign meal`
    [Actions.ADMIN_REJECTED_SUBSCRIBER_CAMPAIGN](payload) {
        return ApiService.post("/admin/campaign-meal/rejected", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // kitchen manager approved subscriber campaign meal
    [Actions.KITCHEN_MANAGER_APPROVED_SUBSCRIBER_CAMPAIGN](payload) {
        return ApiService.post("/kitchen-manager/campaign-meal/approved", payload).then(
            ({data}) => toast('success', data)
        ).catch(
            ({response}) => toast('error', response.data)
        );
    }

    @Action // kitchen manager reject subscriber campaign meal
    [Actions.KITCHEN_MANAGER_REJECTED_SUBSCRIBER_CAMPAIGN](payload) {
        return ApiService.post("/kitchen-manager/campaign-meal/rejected", payload).then(
            ({data}) => toast('success', data)
        ).catch(
            ({response}) => toast('error', response.data)
        );
    }

    @Action // store subscriber campaign meal
    [Actions.STORE_SUBSCRIBER_CAMPAIGN_MEAL](payload) {
        return ApiService.post("/subscriber/campaign-meal/store", payload).then(({data}) =>
            toast('success', data)
        ).catch(({response}) =>
            toast('error', response.data)
        );
    }

    @Action // update subscriber campaign meal
    [Actions.UPDATE_SUBSCRIBER_CAMPAIGN_MEAL](payload) {
        return ApiService.post("/subscriber/campaign-meal/update/" + payload.id, payload.campaign_meal_data)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action //delete subscriber campaign meal
    [Actions.DELETE_SUBSCRIBER_CAMPAIGN_MEAL](payload) {
        return ApiService.delete("/subscriber/campaign-meal/delete/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.EXPORT_CAMPAIGN_INVOICE](payload) {
        return ApiService.pdfDownloadPost("/campaign-meal/export_pdf", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'campaign_meal_invoice.pdf')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}

