import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ConsumptionEnums";
import {Mutations as SettingMutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import router from "@/router"; // importing toast library

export interface ConsumptionData {
    consumptionHistory: object,
    kitchenItems: object,
}

@Module
export default class ConsumptionModule extends VuexModule implements ConsumptionData {

    consumptionHistory = {};
    kitchenItems = {};

    /**
     * shows consumption history data
     * @returns object
     */
    get getConsumptionHistory(): object {
        return this.consumptionHistory;
    }

    /**
     * shows kitchen item list
     * @returns object
     */
    get getKitchenItems(): object {
        return this.kitchenItems;
    }

    @Mutation // setConsumptionHistory
    [Mutations.SET_CONSUMPTION_HISTORY](payload) {
        this.consumptionHistory = payload
    }

    @Mutation // setKitchenItemList
    [Mutations.SET_KITCHEN_ITEMS](payload) {
        this.kitchenItems = payload
    }

    @Action // fetchKitchenItemList
    [Actions.FETCH_CONSUMPTION_HISTORY](payload) {
        return ApiService.post("/consumption/index", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_CONSUMPTION_HISTORY, data.consumptionHistory);
            })
            .catch(({response}) => {
                console.log('ConsumptionModule [FETCH_CONSUMPTION_HISTORY]', response)
                toast('error', response.data)
            });
    }

    @Action // fetchGenericConsumptionData
    [Actions.FETCH_GENERIC_CONSUMPTION_DATA](payload: any = null) {
        return ApiService.get("/kitchen-station/stock/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_KITCHEN_ITEMS, data.kitchenItemStock);
                this.context.commit(SettingMutations.SET_KITCHEN_STATION_LIST, data.kitchenStations);
            })
            .catch(({response}) => {
                console.log('ConsumptionModule [FETCH_KITCHEN_ITEMS]', response)
                toast('error', response.data)
            });
    }

    @Action // addConsumptionItems
    [Actions.ADD_CONSUMPTION_ITEMS](payload) {
        return ApiService.post("/consumption/add", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            console.log('ConsumptionModule [ADD_CONSUMPTION_ITEMS]', response)
            toast('error', response.data)
        });
    }

    @Action
    [Actions.DELETE_CONSUMPTION](payload) {
        return ApiService.delete("/consumption/delete/" + payload.id).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            console.error('ConsumptionModule [DELETE_CONSUMPTION]', response.data.message)
            toast('error', response.data)
        });
    }

    @Action // exportConsumptionHistory
    [Actions.EXPORT_CONSUMPTION_HISTORY](payload) {
        return ApiService.pdfDownloadPost("/consumption/export-excel", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'consumptionHistory' + '.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('ConsumptionModule [EXPORT_CONSUMPTION_HISTORY]', response)
            toast('error', response.data)
        });
    }


}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}

