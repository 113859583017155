import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/MenuPageEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface MenuPageSubs {
    menu_page_subs: Array<object>
}

export interface MenuPages {
    menu_pages: Array<object>
}

export interface MenuPageSub {
    menu_page_sub: any
}

export interface MenuPageSubInfo {
    errors: unknown;
    success: unknown;
    menu_page_subs: MenuPageSubs;
    menu_page_sub: MenuPageSub;
    menu_pages: MenuPages;
}

@Module
export default class MenuPageSubModule extends VuexModule implements MenuPageSubInfo{
    errors = {};
    success = {};
    menu_page_subs = {} as MenuPageSubs;
    menu_page_sub = {} as MenuPageSub;
    menu_pages = {} as MenuPages;

    /**
     * Get authentification success
     * @returns array
     */
    get getMenuPageSubSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getMenuPageSubErrors() {
        return this.errors;
    }

    /**
     * shows permission list
     * @returns object
     */
    get getMenuPageSubs(): object {
        return this.menu_page_subs;
    }

    /**
     * shows permission list
     * @returns object
     */
    get getMenuPageSub(): object {
        return this.menu_page_sub;
    }
    /**
     * shows permission list
     * @returns object
     */
    get getMenuPagesForSub(): object {
        return this.menu_pages;
    }

    @Mutation
    [Mutations.SET_MENU_PAGE_SUB_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_MENU_PAGE_SUB_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched permission list data are saved in this function
    [Mutations.SET_MENU_PAGE_SUB_LIST](payload) {
        this.menu_page_subs = payload
    }

    @Mutation // fetched permission list data are saved in this function
    [Mutations.SET_MENU_PAGE_SUB](payload) {
        this.menu_page_sub = payload
    }


    @Mutation // fetched all menu pages
    [Mutations.SET_MENU_PAGE_LIST_FOR_SUB](payload) {
        this.menu_pages = payload
    }

    @Action // fetch menu_page_subs
    [Actions.FETCH_MENU_PAGE_SUB_LIST]() {
        return ApiService.get("/menu-page-subs")
            .then(({data}) => {
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_LIST, data.menu_page_subs);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_ERROR, response.data.errors);
            });
    }

    @Action // fetch menu_page_sub
    [Actions.FETCH_MENU_PAGE_SUB](payload) {
        return ApiService.get("/menu-page-subs/"  + payload + '/edit')
            .then(({data}) => {
                this.context.commit(Mutations.SET_MENU_PAGE_SUB, data.menu_page_sub);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_ERROR, response.data.errors);
            });
    }

    @Action // store menu_page_sub
    [Actions.STORE_MENU_PAGE_SUB](payload) {
        return ApiService.post("/menu-page-subs", payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_ERROR, response.data.errors);
            });
    }

    @Action // update menu_page_sub
    [Actions.UPDATE_MENU_PAGE_SUB](payload) {
        return ApiService.put("/menu-page-subs/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_ERROR, response.data.errors);
            });
    }

    @Action //delete menu_page_sub
    [Actions.DELETE_MENU_PAGE_SUB](payload) {
        return ApiService.delete("/menu-page-subs/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_MENU_PAGE_SUB_ERROR, response.data.errors);
            });
    }

    @Action //fetch menu page list
    [Actions.FETCH_MENU_PAGE_LIST_FOR_SUB]() {
        return ApiService.get("/menu-page-sub/all-menu-pages")
            .then(({data}) => {
                this.context.commit(Mutations.SET_MENU_PAGE_LIST_FOR_SUB, data.menu_pages);
            })
            .catch(({response}) => {
                toast('error', response.data)
                console.error('ERROR', response.data.message)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
