import {createStore} from "vuex";
import {config} from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ConsumptionModule from "@/store/modules/consumption/ConsumptionModule";
import HrBrandModule from "@/store/modules/setting/HrBrandModule";
import DFeastModule from "@/store/modules/setting/DFeastModule";
import InventoryModule from "@/store/modules/inventory/stockModule";
import ItemModule from "@/store/modules/setting/ItemModule";
import KitchenStationModule from "@/store/modules/setting/KitchenStationModule";
import KitchenSupplyHubModule from "@/store/modules/setting/KitchenSupplyHubModule";
import PurchaseOrderModule from "@/store/modules/purchase/PurchaseOrderModule";
import PurchaseOrderInboundModule from "@/store/modules/purchase/PurchaseOrderInboundModule";
import PurchaseRequestModule from "@/store/modules/purchase/PurchaseRequestModule";
import PermissionModule from "@/store/modules/permission/PermissionModule";
import roleModule from "@/store/modules/permission/RoleModule";
import UserModule from "@/store/modules/user/UserModule";
import VendorModule from "@/store/modules/setting/VendorModule";
import PaymentTypeModule from "@/store/modules/setting/PaymentTypeModule";
import DepartmentModule from "@/store/modules/setting/DepartmentModule";
import LocationModule from "@/store/modules/setting/LocationModule";
import AnnualLeaveDateModule from "@/store/modules/setting/AnnualLeaveDateModule";
import FoodItemModule from "@/store/modules/setting/FoodItemModule";
import DailyFoodItemModule from "@/store/modules/setting/DailyFoodItemModule";
import MenuPageModule from "@/store/modules/menu-page/MenuPageModule";
import MenuPageSubModule from "@/store/modules/menu-page/MenuPageSubModule";
import SubscriptionModule from "@/store/modules/subscription/SubscriptionModule";
import AdminSubscriptionModule from "@/store/modules/subscription/AdminSubscriptionModule";
import AlternativeScheduleModule from "@/store/modules/subscription/AlternativeScheduleModule";
import KitchenManagerSubscriptionModule from "@/store/modules/subscription/KitchenManagerSubscriptionModule";
import CampaignModule from "@/store/modules/campaign/CampaignModule";
import UserAccountModule from "@/store/modules/account/UserAccountModule";
import DashboardModule from "@/store/modules/dashboard/DashboardModule";
import SubscriberImportModule from "@/store/modules/import/SubscriberImportModule";
import TopBarModule from "@/store/modules/topbar/TopBarModule";
import TermAndConditionModule from "@/store/modules/setting/TermAndConditionModule";
import SubscriberTermAndConditionModule from "@/store/modules/subscriber/TermAndConditionModule";
import ChatModule from "@/store/modules/chat/ChatModule";
import MonthlyKitchenCostingModule from "@/store/modules/report/MonthlyKitchenCostingModule";
import MonthlyFoodDeliveryStatusModule from "@/store/modules/report/MonthlyFoodDeliveryStatusModule";
import InventoryHistoryModule from "@/store/modules/report/InventoryHistoryModule";
import InboundHistoryModule from "@/store/modules/report/InboundHistoryModule";
import InboundConsumptionReportModule from "@/store/modules/report/InboundConsumptionReportModule";
import DailyGuestMealReportModule from "@/store/modules/report/DailyGuestMealReportModule";
import CampaignMealReportModule from "@/store/modules/report/CampaignMealReportModule";
import SubscriberAndUnsubscribeReportModule from "@/store/modules/report/SubscriberAndUnsubscribeReportModule";
import MonthlyPurchaseRequestListReportModule from "@/store/modules/report/MonthlyPurchaseRequestListReportModule";


config.rawError = true;

const store = createStore({
    modules: {
        AuthModule,
        BodyModule,
        BreadcrumbsModule,
        ConfigModule,
        ConsumptionModule,
        DailyFoodItemModule,
        DashboardModule,
        DFeastModule,
        HrBrandModule,
        InventoryModule,
        ItemModule,
        KitchenStationModule,
        KitchenSupplyHubModule,
        PurchaseOrderModule,
        PurchaseOrderInboundModule,
        PurchaseRequestModule,
        PermissionModule,
        roleModule,
        UserModule,
        VendorModule,
        MenuPageModule,
        MenuPageSubModule,
        PaymentTypeModule,
        DepartmentModule,
        LocationModule,
        AnnualLeaveDateModule,
        SubscriptionModule,
        AdminSubscriptionModule,
        AlternativeScheduleModule,
        KitchenManagerSubscriptionModule,
        FoodItemModule,
        CampaignModule,
        UserAccountModule,
        SubscriberImportModule,
        TopBarModule,
        TermAndConditionModule,
        SubscriberTermAndConditionModule,
        ChatModule,
        MonthlyKitchenCostingModule,
        MonthlyFoodDeliveryStatusModule,
        InventoryHistoryModule,
        InboundHistoryModule,
        InboundConsumptionReportModule,
        DailyGuestMealReportModule,
        CampaignMealReportModule,
        SubscriberAndUnsubscribeReportModule,
        MonthlyPurchaseRequestListReportModule,
    },

});

export default store;
