enum Actions {
    // Page Menu
    FETCH_MENU_PAGE_LIST = "fetchMenuPageList",
    FETCH_MENU_PAGE = "fetchMenuPage",
    STORE_MENU_PAGE = "storeMenuPage",
    UPDATE_MENU_PAGE = "updateMenuPage",
    DELETE_MENU_PAGE = "deleteMenuPage",

    // Page Menu Sub
    FETCH_MENU_PAGE_SUB_LIST = "fetchMenuPageSubList",
    FETCH_MENU_PAGE_SUB = "fetchMenuPageSub",
    STORE_MENU_PAGE_SUB = "storeMenuPageSub",
    UPDATE_MENU_PAGE_SUB = "updateMenuPageSub",
    DELETE_MENU_PAGE_SUB = "deleteMenuPageSub",
    FETCH_MENU_PAGE_LIST_FOR_SUB = "fetchMenuPageListForSub",
}

enum Mutations {
    // Page Menu
    SET_MENU_PAGE_LIST = "setMenuPageList",
    SET_MENU_PAGE = "setMenuPage",
    SET_MENU_PAGE_ERROR = "setMenuPageError",
    SET_MENU_PAGE_SUCCESS = "setMenuPageSuccess",

    // Page Menu Sub
    SET_MENU_PAGE_SUB_LIST = "setMenuPageSubList",
    SET_MENU_PAGE_LIST_FOR_SUB = "setMenuPageListForSub",
    SET_MENU_PAGE_SUB = "setMenuPageSub",
    SET_MENU_PAGE_SUB_ERROR = "setMenuPageSubError",
    SET_MENU_PAGE_SUB_SUCCESS = "setMenuPageSubSuccess",
}

export {Actions, Mutations};