import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/TopBarEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Notifications {
    notifications: Array<object>
}

export interface TopBarData {
    errors: unknown;
    success: unknown;
    notifications: Notifications;
}

@Module
export default class TopBarModule extends VuexModule implements TopBarData {
    errors = {};
    success = {};
    notifications = {} as Notifications;

    /**
     * shows daily food items data
     * @returns object
     */
    get getNotifications(): object {
        return this.notifications;
    }

    @Mutation // set notifications
    [Mutations.SET_NOTIFICATION_LIST](payload) {
        this.notifications = payload.notifications;
    }

    @Action // fetchDashboardData
    [Actions.FETCH_NOTIFICATION_LIST]() {
        return ApiService.get("/notifications")
            .then(({data}) => {
                this.context.commit(Mutations.SET_NOTIFICATION_LIST, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action
    [Actions.MARK_AS_READ_NOTIFICATION](payload) {
        return ApiService.post("/mark-as-read", payload)
            .then(() => {
                console.log()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}

