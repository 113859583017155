import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface MonthlyPurchaseRequestListData {
    monthly_purchase_request_report_data: object,
}

@Module
export default class MonthlyPurchaseRequestListReportModule extends VuexModule implements MonthlyPurchaseRequestListData {

    monthly_purchase_request_report_data = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getMonthlyPurchaseRequestListReport(): object {
        return this.monthly_purchase_request_report_data;
    }

    @Mutation // setMonthlyPRReport
    [Mutations.SET_MONTHLY_PURCHASE_REQUEST_REPORT](payload) {
        this.monthly_purchase_request_report_data = payload
    }

    @Action // fetchMonthlyPRReport
    [Actions.FETCH_MONTHLY_PURCHASE_REQUEST_REPORT](payload) {
        return ApiService.post("/report/monthly-purchase-request-report", payload).then(({data}) => {
            this.context.commit(Mutations.SET_MONTHLY_PURCHASE_REQUEST_REPORT, data.monthly_purchase_request_report_data);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // exportMonthlyPRReport
    [Actions.EXPORT_MONTHLY_PURCHASE_REQUEST_REPORT](payload) {
        const year = payload.year

        return ApiService.pdfDownloadPost("/report/monthly-purchase-request-report/download", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', year + '_monthly_purchase_request_report.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
