enum Actions {

    FETCH_SUBSCRIBER_TERM_AND_CONDITION_LIST = "fetchSubscriberTermAndConditionList",

}

enum Mutations {


    SET_SUBSCRIBER_TERM_AND_CONDITION_LIST = "setSubscriberTermAndConditionList",

}

export {Actions, Mutations};
