enum Actions {

    FETCH_SUBSCRIPTION_LIST = "fetchSubscriptionList",
    FETCH_ADMIN_TODAY_SUBSCRIPTION_LIST = "fetchAdminTodaySubscriptionList",
    FETCH_ADMIN_TODAY_SUBSCRIPTION_DELIVERY_LIST = "fetchAdminTodaySubscriptionDeliveryList",
    STORE_MANUALLY_GUEST_MEAL = "storeManuallyGuestMeal",
    FETCH_ADMIN_SUBSCRIBER_LIST = "fetchAdminSubscriberList",
    FETCH_ADMIN_SUBSCRIBER_SUBSCRIPTION_LIST = "fetchAdminSubscriberSubscriptionList",
    FETCH_ADMIN_SUBSCRIBER_LATEST_SUBSCRIPTION = "fetchAdminSubscriberLatestSubscription",
    ADMIN_SUBSCRIBER_START_SUBSCRIPTION = "adminSubscriberStartSubscription",
    ADMIN_SUBSCRIBER_CANCEL_SUBSCRIPTION = "adminSubscriberCancelSubscription",

    FETCH_ADMIN_CANCELLED_SUBSCRIBER_LIST = "fetchCancelledSubscriptionList",
    FETCH_ADMIN_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST = "fetchAdminSubscriberDailySubscriptionList",

    FETCH_ADMIN_SUBSCRIBER_REQUEST_GUEST_MEAL_LIST = "fetchAdminSubscriberRequestGuestMealList",
    DELETE_ADMIN_SUBSCRIBER_REQUEST_GUEST_MEAL = "deleteAdminSubscriberRequestGuestMeal",

    FETCH_ADMIN_SUBSCRIBER_LOCATION_LIST = "fetchAdminSubscriberLocationList",
    DELETE_ADMIN_SUBSCRIBER_LOCATION = "deleteAdminSubscriberLocation",

    FETCH_ADMIN_INDIVIDUALLY_SUBSCRIBER = "fetchAdminIndividuallySubscriber",
}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    SET_SUBSCRIPTION_LIST = "setSubscriptionList",
    SET_ADMIN_TODAY_SUBSCRIPTION_LIST = "setAdminTodaySubscription",
    SET_ADMIN_SUBSCRIBER_LIST = "setAdminSubscriberList",
    SET_ADMIN_CANCELLED_SUBSCRIBER_LIST = "setAdminCancelledSubscriberList",
    SET_ADMIN_SUBSCRIBER_SUBSCRIPTION_LIST = "setAdminSubscriberSubscriptionList",
    SET_ADMIN_SUBSCRIBER_LATEST_SUBSCRIPTION = "setAdminSubscriberLatestSubscription",
    SET_ADMIN_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST = "setAdminSubscriberDailySubscriptionList",

    SET_ADMIN_SUBSCRIBER_REQUEST_GUEST_MEAL_LIST = "setAdminSubscriberRequestGuestMealList",
    SET_ADMIN_SUBSCRIBER_LOCATION_LIST = "setAdminSubscriberRLocationList",

    SET_ADMIN_INDIVIDUALLY_SUBSCRIBER = "setAdminIndividuallySubscriber",
}

export {Actions, Mutations};
