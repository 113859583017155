enum Actions {
    FETCH_CHAT_USERS_LIST = "fetchChatUsersList",
    FETCH_CHAT_ADMIN_USERS_LIST = "fetchChatAdminUsersList",
    FETCH_CHAT_MESSAGES = "fetchChatMessages",
    FETCH_ADMIN_CHAT_MESSAGES = "fetchAdminChatMessages",
    STORE_CHAT_MESSAGE = "storeChatMessage",
}

enum Mutations {
    SET_CHAT_USERS_LIST = "setChatUsersList",
    SET_CHAT_ADMIN_USERS_LIST = "setChatAdminUsersList",
    SET_CHAT_MESSAGES = "setChatMessages",
    SET_ADMIN_CHAT_MESSAGES = "setAdminChatMessages",
}

export {Actions, Mutations};
