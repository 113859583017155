import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface dFeastData {
    d_feasts: object
}

@Module
export default class DFeastModule extends VuexModule implements dFeastData {

    d_feasts = {}

    /**
     * shows feast list
     * @returns object
     */
    get getDFeastList(): object {
        return this.d_feasts;
    }

    @Mutation // fetched feast list data are saved in this function
    [Mutations.SET_D_FEAST_LIST](payload) {
        this.d_feasts = payload
    }

    @Action // fetchDFeast
    [Actions.FETCH_D_FEAST_LIST]() {
        return ApiService.get("/d-feasts").then(({data}) => {
            this.context.commit(Mutations.SET_D_FEAST_LIST, data.d_feasts);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // storeDFeast
    [Actions.STORE_D_FEAST](payload) {
        return ApiService.post("/d-feasts", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // updateDFeast
    [Actions.UPDATE_D_FEAST](payload) {
        return ApiService.put("/d-feasts/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //deleteDFeast
    [Actions.DELETE_D_FEAST](payload) {
        return ApiService.delete("/d-feasts/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}


// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
