import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import router from "@/router";
import {useToast} from "vue-toastification";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
}

export interface MenuPermission {
    menu_permissions: string;
}

export interface Location {
    locations: string;
}

export interface Department {
    departments: string;
}

export interface Religion {
    religions: string;
}

export interface UserAuthInfo {
    errors: unknown;
    successes: unknown;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    successes = {};
    user = {} as User;
    menu_permissions = {} as MenuPermission;
    locations = {} as Location;
    departments = {} as Department;
    religions = {} as Religion;
    isAuthenticated = !!JwtService.getToken();
    responseStatus: any = null

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Get current menu_permissions array
     * @returns menu_permissions
     */
    get menuPermissions(): MenuPermission {
        return this.menu_permissions;
    }

    /**
     * Get current menu_permissions array
     * @returns departments
     */
    get getRegDepartment(): Department {
        return this.departments;
    }

    /**
     * Get current menu_permissions array
     * @returns locations
     */
    get getRegLocations(): Location {
        return this.locations;
    }

    /**
     * Get current menu_permissions array
     * @returns religions
     */
    get getRegReligion(): Religion {
        return this.religions;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getAuthErrors() {
        return this.errors;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getResponseStatus() {
        return this.responseStatus;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getSuccesses() {
        return this.successes;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_RESPONSE_STATUS](statusCode) {
        this.responseStatus = statusCode;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.successes = {...success};
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.menu_permissions = user.menu_permissions;
        this.errors = {};
        this.successes = {};
        JwtService.saveToken(user.api_token);
        JwtService.saveMenu(user.menu_permissions);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.SET_MENU_PERMISSIONS](menu_permissions) {
        this.menu_permissions = menu_permissions;
    }

    @Mutation
    [Mutations.SET_REG_LOCATION](locations) {
        this.locations = locations;
    }

    @Mutation
    [Mutations.SET_REG_DEPARTMENT](departments) {
        this.departments = departments;
    }

    @Mutation
    [Mutations.SET_REG_RELIGION](religions) {
        this.religions = religions;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.menu_permissions = {} as MenuPermission;
        this.errors = [];
        this.successes = [];
        JwtService.destroyToken();
        JwtService.destroyMenu();
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post("login", credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return ApiService.post("register", credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUCCESS, data);
                this.context.commit(Mutations.SET_RESPONSE_STATUS, 200);
                // this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                this.context.commit(Mutations.SET_RESPONSE_STATUS, response.status);

            });
    }

    @Action
    [Actions.GET_REG_GENERIC_DATA]() {
        return ApiService.get("/reg-genericData",)
            .then(({data}) => {
                this.context.commit(Mutations.SET_REG_DEPARTMENT, data.departments);
                this.context.commit(Mutations.SET_REG_LOCATION, data.locations);
                this.context.commit(Mutations.SET_REG_RELIGION, data.religions);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post("/forgot_password", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.UPDATE_PASSWORD](payload) {
        return ApiService.post("/update_password", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken() && JwtService.getMenu()) {
            ApiService.setHeader();
            ApiService.post("verify_token", payload).then(({data}) => {
                this.context.commit(Mutations.SET_AUTH, data);
            }).catch(({response}) => {
                toast('error', response.data)
                //this.context.commit(Mutations.SET_ERROR, response.data.errors);
                this.context.commit(Mutations.PURGE_AUTH);
                router.push({name: 'sign-in'})
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.FETCH_CODES_FOR_REG](credentials) {
        return ApiService.get("get/register/codes", credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_REG_LOCATION, data.locations);
                this.context.commit(Mutations.SET_REG_DEPARTMENT, data.departments);
                this.context.commit(Mutations.SET_REG_RELIGION, data.religions);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }

}