import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/UserAccountEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";
import router from "@/router";
import {computed} from "vue";
import store from "@/store";
const user = computed(() => store.getters.currentUser.user);

export interface Users {
    users: Array<object>
}

export interface User {
    user: any
}

export interface SubscriberSubscription {
    subscriptions: Array<object>
}

export interface UserInfo {
    errors: unknown;
    success: unknown;
    users: Users;
    user: User;
    subscriptions: SubscriberSubscription;
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
    errors = {};
    success = {};
    users = {} as Users;
    user = {} as User;
    subscriptions = {} as SubscriberSubscription;

    /**
     * Get authentification success
     * @returns array
     */
    get getUserAccountSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getUserAccountErrors() {
        return this.errors;
    }

    /**
     * shows user list
     * @returns object
     */
    get getAdminSubscriptionUsers(): object {
        return this.users;
    }

    /**
     * shows user list
     * @returns object
     */
    get getAdminSubscriptionUser(): object {
        return this.user;
    }

    /**
     * shows user list
     * @returns object
     */
    get getSubscriberSubscriptions(): object {
        return this.subscriptions;
    }

    @Mutation
    [Mutations.SET_ADMIN_ACCOUNT_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ADMIN_ACCOUNT_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_ADMIN_ACCOUNT](payload) {
        this.user = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_ACCOUNT_LIST](payload) {
        this.users = payload
    }

    @Mutation
    [Mutations.SET_SUBSCRIBER_ACCOUNT_LIST](payload) {
        this.subscriptions = payload
    }

    @Action // fetch admin->subscriber account information
    [Actions.FETCH_ADMIN_ACCOUNT_LIST](payload) {
        return ApiService.post("/admin/subscribers/account/list", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_ACCOUNT_LIST, data.users);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch admin->subscriber account information download as pdf
    [Actions.FETCH_ADMIN_ACCOUNT_LIST_DOWNLOAD_PDF](payload) {
        return ApiService.pdfDownloadPost("/admin/subscribers/account/list/download-pdf", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'monthly_user_account.pdf')
                document.body.appendChild(link)
                link.click()
             })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch admin->subscriber account information download as excel
    [Actions.FETCH_ADMIN_ACCOUNT_LIST_DOWNLOAD_EXCEL](payload) {
        return ApiService.pdfDownloadPost("/admin/subscribers/account/list/download-excel", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'monthly_user_account.xlsx')
                document.body.appendChild(link)
                link.click()
             })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch admin->subscriber account information
    [Actions.FETCH_ADMIN_ACCOUNT](payload) {
        return ApiService.post("/admin/subscriber/account/list", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_ACCOUNT, data.user);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ADMIN_ACCOUNT_ERROR, response.data.errors);
            });
    }

    @Action // fetch subscriber account information
    [Actions.FETCH_SUBSCRIBER_ACCOUNT_LIST](payload) {
        return ApiService.post("/subscriber/account/list", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_ACCOUNT_LIST, data.subscriptions);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber account information download
    [Actions.FETCH_SUBSCRIBER_ACCOUNT_LIST_DOWNLOAD](payload) {
        return ApiService.pdfDownloadPost("/subscriber/account/list/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', user.value.name + '.pdf')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
