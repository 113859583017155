enum Actions {

    // HrBrand Module
    FETCH_HR_BRAND_LIST = "fetchHrBrandList",
    STORE_HR_BRAND = "storeHrBrand",
    UPDATE_HR_BRAND = "updateHrBrand",
    DELETE_HR_BRAND = "deleteHrBrand",

    // DFeast Module
    FETCH_D_FEAST_LIST = "fetchDFeastList",
    STORE_D_FEAST = "storeDFeast",
    UPDATE_D_FEAST = "updateDFeast",
    DELETE_D_FEAST = "deleteDFeast",

    // Item Module
    FETCH_ITEM_LIST = "fetchItemList",
    STORE_ITEM = "storeItem",
    UPDATE_ITEM = "updateItem",
    DELETE_ITEM = "deleteItem",

    // Kitchen-Station Module
    FETCH_KITCHEN_STATION_LIST = "fetchKitchenStationList",
    STORE_KITCHEN_STATION = "storeKitchenStation",
    UPDATE_KITCHEN_STATION = "updateKitchenStation",
    DELETE_KITCHEN_STATION = "deleteKitchenStation",

    // Kitchen-Supply-hubs Module
    FETCH_KITCHEN_SUPPLY_HUB_LIST = "fetchKitchenSupplyHubList",
    FETCH_HUB_KITCHEN_STATION_LIST = "fetchHubKitchenStationList",
    STORE_KITCHEN_SUPPLY_HUB = "storeKitchenSupplyHub",
    UPDATE_KITCHEN_SUPPLY_HUB = "updateKitchenSupplyHub",
    DELETE_KITCHEN_SUPPLY_HUB = "deleteKitchenSupplyHub",

    // Vendor Module
    FETCH_VENDOR_LIST = "fetchVendorList",
    STORE_VENDOR = "storeVendor",
    UPDATE_VENDOR = "updateVendor",
    DELETE_VENDOR = "deleteVendor",

    // Payment Type Module
    FETCH_PAYMENT_TYPE_LIST = "fetchPaymentTypeList",

    // Department Module
    FETCH_DEPARTMENT_LIST = "fetchDepartmentList",
    FETCH_DEPARTMENT_LIST_FOR_REG = "fetchDepartmentListForReg",
    STORE_DEPARTMENT = "storeDepartment",
    UPDATE_DEPARTMENT = "updateDepartment",
    DELETE_DEPARTMENT = "deleteDepartment",

    // Location Module
    FETCH_LOCATION_LIST = "fetchLocationList",
    FETCH_LOCATION_LIST_FOR_REG = "fetchLocationListForReg",
    STORE_LOCATION = "storeLocation",
    UPDATE_LOCATION = "updateLocation",
    DELETE_LOCATION = "deleteLocation",

    // Term and Condition Module
    FETCH_TERM_AND_CONDITION_LIST = "fetchTermAndConditionList",
    STORE_TERM_AND_CONDITION = "storeTermAndCondition",
    UPDATE_TERM_AND_CONDITION = "updateTermAndCondition",
    DELETE_TERM_AND_CONDITION = "deleteTermAndCondition",

    // Annual leave date Module
    FETCH_ANNUAL_LEAVE_DATE_LIST = "fetchAnnualLeaveDateList",
    FETCH_ANNUAL_LEAVE_DATE_LIST_FOR_REG = "fetchAnnualLeaveDateListForReg",
    STORE_ANNUAL_LEAVE_DATE = "storeAnnualLeaveDate",
    UPDATE_ANNUAL_LEAVE_DATE = "updateAnnualLeaveDate",
    DELETE_ANNUAL_LEAVE_DATE = "deleteAnnualLeaveDate",

    // Food Item Module
    FETCH_FOOD_ITEM_LIST = "fetchFoodItemList",
    FETCH_FOOD_ITEM = "fetchFoodItem",
    STORE_FOOD_ITEM = "storeFoodItem",
    UPDATE_FOOD_ITEM = "updateFoodItem",
    DELETE_FOOD_ITEM = "deleteFoodItem",

    // Daily Food Item Module
    FETCH_DAILY_FOOD_ITEM_LIST = "fetchDailyFoodItemList",
    FETCH_DAILY_FOOD_ITEM_EXISTING_DATE = "fetchDailyFoodItemExistingDate",
    FETCH_DAILY_FOOD_ITEM = "fetchDailyFoodItem",
    STORE_DAILY_FOOD_ITEM = "storeDailyFoodItem",
    UPDATE_DAILY_FOOD_ITEM = "updateDailyFoodItem",
    DELETE_DAILY_FOOD_ITEM = "deleteDailyFoodItem",

}

enum Mutations {
    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    // HR Brand Module
    SET_HR_BRAND_LIST = "setHrBrandList",

    // D Feast Module
    SET_D_FEAST_LIST = "setDFeastList",



    // Item Module
    SET_ITEM_LIST = "setItemList",

    // Vendor Module
    SET_VENDOR_LIST = "setVendorList",

    // Kitchen Station
    SET_KITCHEN_STATION_LIST = "setKitchenStationList",

    // Payment type
    SET_PAYMENT_TYPE_LIST = "setPaymentTypeList",

    // Food Item
    SET_FOOD_ITEM_LIST = "setFoodItemList",
    SET_FOOD_ITEM = "setFoodItem",

    // Daily Food Item
    SET_DAILY_FOOD_ITEM_LIST = "setDailyFoodItemList",
    SET_DAILY_FOOD_ITEM_EXISTING_DATE = "setDailyFoodItemExistingDate",
    SET_DAILY_FOOD_ITEM = "setDailyFoodItem",

    // Department
    SET_DEPARTMENT_LIST = "setDepartmentList",
    SET_DEPARTMENT_LIST_FOR_REG = "setDepartmentListForReg",

    // Location
    SET_LOCATION_LIST = "setLocationList",
    SET_LOCATION_LIST_FOR_REG = "setLocationListForReg",

    // Term and condition
    SET_TERM_AND_CONDITION_LIST = "setTermAndConditionList",

    // Annual leave date type
    SET_ANNUAL_LEAVE_DATE_LIST = "setAnnualLeaveDateList",

    // Kitchen-Supply-Hub Module
    SET_KITCHEN_SUPPLY_HUB_LIST = "setKitchenSupplyHubList", // sets kitchen supply list
    SET_HUB_KITCHEN_STATION_LIST = "setHubKitchenStationList" // sets kitchen station list
}

export {Actions, Mutations};
