

enum Actions {
    FETCH_KITCHEN_STOCK = "fetchKitchenStock",
    EXPORT_INVENTORY_REPORT = "exportInventoryReport",
}

enum Mutations {
    SET_KITCHEN_STOCK = "setKitchenStock",
}

export {Actions, Mutations};
