enum Actions {

    FETCH_GUEST_MEAL_LIST = "fetchGuestMealList",
    STORE_GUEST_MEAL = "storeGuestMeal",
    DELETE_GUEST_MEAL = "deleteGuestMeal",

    FETCH_ALTERNATIVE_LOCATION_LIST = "fetchAlternativeLocationList",
    STORE_ALTERNATIVE_LOCATION = "storeAlternativeLocation",
    DELETE_ALTERNATIVE_LOCATION = "deleteAlternativeLocation",

    FETCH_LOCATION_LIST_FOR_ALTERNATIVE_LOCATION = "fetchLocationListForAlternativeLocation",
}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    SET_GUEST_MEAL_LIST = "setSGuestMealList",

    SET_ALTERNATIVE_LOCATION_LIST = "setAlternativeLocationList",
    SET_LOCATION_LIST_FOR_ALTERNATIVE_LOCATION = "setLocationListForAlternativeLocation",
}

export {Actions, Mutations};