import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ExcelImportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";


export interface SubscriberImportInfo {
    errors: unknown;
    success: unknown;
}

@Module
export default class SubscriberImportModule extends VuexModule implements SubscriberImportInfo{
    errors = {};
    success = {};

    /**
     * Get authentification success
     * @returns array
     */
    get getSubscriberImportSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getSubscriberImportErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Action // store subscriber import file
    [Actions.STORE_SUBSCRIBER_IMPORT_LIST](payload) {
        return ApiService.post("/import/subscriber", payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}

