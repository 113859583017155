import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface hrBrandData {
    hrBrandList: object
}

@Module
export default class HrBrandModule extends VuexModule implements hrBrandData {

    hrBrandList = {}

    /**
     * shows hr brand list
     * @returns object
     */
    get getHrBrandList(): object {
        return this.hrBrandList;
    }

    @Mutation // fetched hr brand list data are saved in this function
    [Mutations.SET_HR_BRAND_LIST](payload) {
        this.hrBrandList = payload
    }

    @Action // fetchHrBrand
    [Actions.FETCH_HR_BRAND_LIST]() {
        return ApiService.get("/hr-brands")
            .then(({data}) => {
                this.context.commit(Mutations.SET_HR_BRAND_LIST, data.hr_brands);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // storeHrBrand
    [Actions.STORE_HR_BRAND](payload) {
        return ApiService.post("/hr-brands", payload.formData)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // updateHrBrand
    [Actions.UPDATE_HR_BRAND](payload) {
        return ApiService.put("/hr-brands/" + payload.formData.id, payload.formData)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //deleteHrBrand
    [Actions.DELETE_HR_BRAND](payload) {
        return ApiService.delete("/hr-brands/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }


}


// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
