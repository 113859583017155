enum Actions {

    FETCH_NOTIFICATION_LIST = "fetchNotificationList",
    MARK_AS_READ_NOTIFICATION = "markAsReadNotification",

}

enum Mutations {
    SET_NOTIFICATION_LIST = 'setNotificationList',
}

export {Actions, Mutations};
