enum Actions {

    // Role Module
    FETCH_ROLE_LIST = "fetchRoleList",
    FETCH_ROLE = "fetchRole",
    STORE_ROLE = "storeRole",
    UPDATE_ROLE = "updateRole",
    DELETE_ROLE = "deleteRole",

    // Permission Module
    FETCH_PERMISSION_LIST = "fetchPermissionList",
    FETCH_PERMISSION = "fetchPermission",
    STORE_PERMISSION = "storePermission",
    UPDATE_PERMISSION = "updatePermission",
    DELETE_PERMISSION = "deletePermission",
    DELETE_PERMISSIONS = "deletePermissions",

}

enum Mutations {

    // Role Module
    SET_ROLE_LIST = "setRoleList",
    SET_ROLE = "setRole",
    SET_ROLE_ERROR = "setRoleError",
    SET_ROLE_SUCCESS = "setRoleSuccess",

    // Permission Module
    SET_PERMISSION_LIST = "setPermissionList",
    SET_PERMISSION = "setPermission",
    SET_PERMISSION_ERROR = "setPermissionError",
    SET_PERMISSION_SUCCESS = "setPermissionSuccess",
}

export {Actions, Mutations};