enum Actions {
    FETCH_DASHBOARD_DATA = "fetchDashboardData",
    FETCH_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD = "fetchUpcomingCampaignMealFroDashboard",
    FETCH_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART = "fetchMonthlySubscriberAndUnsubscribeChart",
    FETCH_MONTHLY_PURCHASE_REQUEST_CHART = "fetchMonthlyPurchaseRequestChart",
}

enum Mutations {
    SET_DASHBOARD_DATA = 'setDashboardData',
    SET_DAILY_FOOD_ITEM_LIST = "setDailyFoodItemList",
    SET_FEAST_LIST = 'SetFeastList',
    SET_FOOD_ITEM_LIST = "setFoodItemList",
    SET_RELIGION_COUNT = 'setReligionCount',
    SET_MEAL_COUNT_PER_LOCATION = 'setMealPerLocation',
    SET_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD = 'setUpcomingCampaignMealFroDashboard',
    SET_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART = 'setMonthlySubscriberAndUnsubscribeChart',
    SET_MONTHLY_PURCHASE_REQUEST_CHART = 'setMonthlyPurchaseRequestChart',
}

export {Actions, Mutations};
