import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/PermissionEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Roles {
    roles: Array<object>
}

export interface Role {
    role: any
}

export interface RoleInfo {
    errors: unknown;
    success: unknown;
    roles: Roles;
    role: Role;
}

@Module
export default class RoleModule extends VuexModule implements RoleInfo {
    errors = {};
    success = {};
    roles = {} as Roles;
    role = {} as Role;

    /**
     * Get authentification success
     * @returns array
     */
    get getRoleSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getRoleErrors() {
        return this.errors;
    }

    /**
     * shows permission list
     * @returns object
     */
    get getRoles(): object {
        return this.roles;
    }

    /**
     * shows permission list
     * @returns object
     */
    get getRole(): object {
        return this.role;
    }

    @Mutation
    [Mutations.SET_ROLE_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ROLE_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched permission list data are saved in this function
    [Mutations.SET_ROLE_LIST](payload) {
        this.roles = payload
    }

    @Mutation // fetched permission list data are saved in this function
    [Mutations.SET_ROLE](payload) {
        this.role = payload
    }

    @Action // fetch roles
    [Actions.FETCH_ROLE_LIST]() {
        return ApiService.get("/roles").then(({data}) => {
            this.context.commit(Mutations.SET_ROLE_LIST, data.roles);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetch role
    [Actions.FETCH_ROLE](payload) {
        return ApiService.get("/roles/" + payload + '/edit').then(({data}) => {
            this.context.commit(Mutations.SET_ROLE, data.role);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // store permission
    [Actions.STORE_ROLE](payload) {
        return ApiService.post("/roles", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // update permission
    [Actions.UPDATE_ROLE](payload) {
        return ApiService.put("/roles/" + payload.id, payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action //delete permission
    [Actions.DELETE_ROLE](payload) {
        return ApiService.delete("/roles/" + payload.id).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
