import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface DailyGuestMealData {
    campaign_meal_report: object,
}

@Module
export default class CampaignMealReportModule extends VuexModule implements DailyGuestMealData {

    campaign_meal_report = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getCampaignMealReport(): object {
        return this.campaign_meal_report;
    }

    @Mutation // setCampaignMealReport
    [Mutations.SET_CAMPAIGN_MEAL_REPORT](payload) {
        this.campaign_meal_report = payload
    }

    @Action // fetchCampaignMealReport
    [Actions.FETCH_CAMPAIGN_MEAL_REPORT](payload) {
        return ApiService.post("/report/campaign-meal-report", payload).then(({data}) => {
            this.context.commit(Mutations.SET_CAMPAIGN_MEAL_REPORT, data.campaign_meal_report);
        }).catch(({response}) => {
            console.log('CampaignMealReportModule [FETCH_CAMPAIGN_MEAL_REPORT]', response)
            toast('error', response.data)
        });
    }

    @Action // exportCampaignMealReport
    [Actions.EXPORT_CAMPAIGN_MEAL_REPORT](payload) {
        const from = moment(payload.date[0]).format('YYYY-MM-DD')
        const to = moment(payload.date[1]).format('YYYY-MM-DD')

        return ApiService.pdfDownloadPost("/report/campaign-meal-report/export", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', from + '_' + to + '_campaign_meal_report.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('CampaignMealReportModule [EXPORT_CAMPAIGN_MEAL_REPORT]', response)
            toast('error', response.data)
        });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
