enum Actions {

    FETCH_KITCHEN_MANAGER_SUBSCRIPTION_COUNTER_LIST = "fetchKitchenManagerSubscriptionCounterList",
}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    SET_KITCHEN_MANAGER_SUBSCRIPTION_COUNTER_LIST = "setKitchenManagerSubscriptionCounterList",
    SET_KITCHEN_MANAGER_SUBSCRIPTION_TOTAL_COUNTER = "setKitchenManagerSubscriptionTotalCounter",
}

export {Actions, Mutations};