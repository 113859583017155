import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface InboundConsumptionReportData {
    inventory_consumption_report: object,
}

@Module
export default class InboundConsumptionReportModule extends VuexModule implements InboundConsumptionReportData {

    inventory_consumption_report = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getInboundConsumptionReport(): object {
        return this.inventory_consumption_report;
    }

    @Mutation // setInboundHistory
    [Mutations.SET_INBOUND_CONSUMPTION_REPORT](payload) {
        this.inventory_consumption_report = payload
    }

    @Action // fetchInboundConsumptionReport
    [Actions.FETCH_INBOUND_CONSUMPTION_REPORT](payload) {
        return ApiService.post("/report/inventory-consumption", payload).then(({data}) => {
            this.context.commit(Mutations.SET_INBOUND_CONSUMPTION_REPORT, data.inbound_consumption_report);
        }).catch(({response}) => {
            console.log('InboundConsumptionReportModule [FETCH_INBOUND_CONSUMPTION_REPORT]', response)
            toast('error', response.data)
        });
    }

    @Action // exportInboundConsumptionReport
    [Actions.EXPORT_INBOUND_CONSUMPTION_REPORT](payload) {
        const from = moment(payload.date[0]).format('YYYY-MM-DD')
        const to = moment(payload.date[1]).format('YYYY-MM-DD')

        return ApiService.pdfDownloadPost("/report/inventory-consumption/export", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', from + '_' + to + '_' + payload.name + '_inbound_consumption_report.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('InboundConsumptionReportModule [EXPORT_INBOUND_CONSUMPTION_REPORT]', response)
            toast('error', response.data)
        });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
