import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SubscriberEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface SubscriberTermAndCondition {
    term_and_conditions: Array<object>
}

export interface TermAndConditionInfo {
    errors: unknown;
    success: unknown;
    term_and_conditions: SubscriberTermAndCondition;
}

@Module
export default class TermAndConditionModule extends VuexModule implements TermAndConditionInfo{
    errors = {};
    success = {};
    term_and_conditions = {} as SubscriberTermAndCondition;

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriberTermAndConditions(): object {
        return this.term_and_conditions;
    }

    @Mutation
    [Mutations.SET_SUBSCRIBER_TERM_AND_CONDITION_LIST](payload) {
        this.term_and_conditions = payload
    }

    @Action // fetch term_and_conditions
    [Actions.FETCH_SUBSCRIBER_TERM_AND_CONDITION_LIST]() {
        return ApiService.get("/subscriber/term-and-conditions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_TERM_AND_CONDITION_LIST, data.term_and_conditions);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}