import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface KitchenStations {
    kitchen_stations: Array<object>
}

export interface MonthlyKitchenCostings {
    monthly_kitchen_costings: Array<object>
}

export interface MonthlyKitchenCostingInfo {
    errors: unknown;
    success: unknown;
    kitchen_stations: KitchenStations;
    monthly_kitchen_costings: MonthlyKitchenCostings;
}

@Module
export default class MonthlyKitchenCostingModule extends VuexModule implements MonthlyKitchenCostingInfo {
    errors = {};
    success = {};
    kitchen_stations = {} as KitchenStations;
    monthly_kitchen_costings = {} as MonthlyKitchenCostings;

    /**
     * shows user list
     * @returns object
     */
    get getMonthlyKitchenCostingReport(): object {
        return this.monthly_kitchen_costings;
    }

    /**
     * shows user list
     * @returns object
     */
    get getKitchenStationReport(): object {
        return this.kitchen_stations;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_MONTHLY_KITCHEN_COSTING_LIST](payload) {
        this.monthly_kitchen_costings = payload
    }

    @Mutation
    [Mutations.SET_KITCHEN_STATION_LIST](payload) {
        this.kitchen_stations = payload
    }

    @Action // fetch monthly kitchen costing
    [Actions.FETCH_MONTHLY_KITCHEN_COSTING_LIST](payload) {
        return ApiService.post("/report/monthly-kitchen-costing", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_MONTHLY_KITCHEN_COSTING_LIST, data.monthly_kitchen_costings);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch monthly kitchen costing
    [Actions.FETCH_KITCHEN_STATION_LIST]() {
        return ApiService.get("/report/kitchen-stations")
            .then(({data}) => {
                this.context.commit(Mutations.SET_KITCHEN_STATION_LIST, data.kitchen_stations);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // monthly kitchen costing download as pdf
    [Actions.FETCH_MONTHLY_KITCHEN_COSTING_DOWNLOAD_PDF](payload) {
        return ApiService.pdfDownloadPost("/report/monthly-kitchen-costing/download-pdf", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'monthly_kitchen_costing.pdf')
                document.body.appendChild(link)
                link.click()
             })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // monthly kitchen costing download as excel
    [Actions.FETCH_MONTHLY_KITCHEN_COSTING_DOWNLOAD_EXCEL](payload) {
        return ApiService.pdfDownloadPost("/report/monthly-kitchen-costing/download-excel", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'monthly_kitchen_costing.xlsx')
                document.body.appendChild(link)
                link.click()
             })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
