import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface DailyGuestMealData {
    daily_guest_meal: object,
}

@Module
export default class DailyGuestMealReportModule extends VuexModule implements DailyGuestMealData {

    daily_guest_meal = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getDailyGuestMealReport(): object {
        return this.daily_guest_meal;
    }

    @Mutation // setInboundHistory
    [Mutations.SET_DAILY_GUEST_MEAL_REPORT](payload) {
        this.daily_guest_meal = payload
    }

    @Action // fetchDailyGuestMealReport
    [Actions.FETCH_DAILY_GUEST_MEAL_REPORT](payload) {
        return ApiService.post("/report/daily-guest-meal", payload).then(({data}) => {
            this.context.commit(Mutations.SET_DAILY_GUEST_MEAL_REPORT, data.daily_guest_meal);
        }).catch(({response}) => {
            console.log('DailyGuestMealReportModule [FETCH_INBOUND_CONSUMPTION_REPORT]', response)
            toast('error', response.data)
        });
    }

    @Action // exportDailyGuestMealReport
    [Actions.EXPORT_DAILY_GUEST_MEAL_REPORT](payload) {
        const from = moment(payload.date[0]).format('YYYY-MM-DD')
        const to = moment(payload.date[1]).format('YYYY-MM-DD')

        return ApiService.pdfDownloadPost("/report/daily-guest-meal/export", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', from + '_' + to + '_daily_guest_meal_report.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('DailyGuestMealReportModule [EXPORT_INBOUND_CONSUMPTION_REPORT]', response)
            toast('error', response.data)
        });
    }
}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
