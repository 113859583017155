import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface KitchenStationData {
    KitchenStationList: object
}

@Module
export default class KitchenStationModule extends VuexModule implements KitchenStationData {

    KitchenStationList = {}

    /**
     * shows Kitchen-Station list
     * @returns object
     */
    get getKitchenStationList(): object {
        return this.KitchenStationList;
    }

    @Mutation // fetched Kitchen-Station list data are saved in this function
    [Mutations.SET_KITCHEN_STATION_LIST](payload) {
        this.KitchenStationList = payload
    }

    @Action // fetchKitchenStation
    [Actions.FETCH_KITCHEN_STATION_LIST]() {
        return ApiService.get("/kitchen-stations")
            .then(({data}) => {
                this.context.commit(Mutations.SET_KITCHEN_STATION_LIST, data.kitchen_stations);
            })
            .catch(({response}) => {
                console.error('KitchenStationModule [FETCH_KITCHEN_STATION_LIST]', response.data.message)
            });
    }

    @Action // storeKitchenStation
    [Actions.STORE_KITCHEN_STATION](payload) {
        return ApiService.post("/kitchen-stations", payload.formData)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('KitchenStationModule [STORE_KITCHEN_STATION]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action // updateKitchenStation
    [Actions.UPDATE_KITCHEN_STATION](payload) {
        return ApiService.put("/kitchen-stations/" + payload.formData.id, payload.formData)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('KitchenStationModule [UPDATE_KITCHEN_STATION]', response.data.message)
                toast('error', response.data)
            });
    }

    @Action //deleteKitchenStation
    [Actions.DELETE_KITCHEN_STATION](payload) {
        return ApiService.delete("/kitchen-stations/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                console.error('KitchenStationModule [DELETE_KITCHEN_STATION]', response.data.message)
                toast('error', response.data)
            });
    }


}


// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}