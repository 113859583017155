import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface FoodItems {
    food_items: Array<object>
}

export interface FoodItem {
    food_item: any
}

export interface FoodItemInfo {
    errors: unknown;
    success: unknown;
    food_items: FoodItems;
    food_item: FoodItem;
}

@Module
export default class FoodItemModule extends VuexModule implements FoodItemInfo{
    errors = {};
    success = {};
    food_items = {} as FoodItems;
    food_item = {} as FoodItem;

    /**
     * Get authentification success
     * @returns array
     */
    get getFoodItemSuccess() {
        return this.success;
    }
    /**
     * Get authentification errors
     * @returns array
     */
    get getFoodItemErrors() {
        return this.errors;
    }

    /**
     * shows food_item list
     * @returns object
     */
    get getFoodItems(): object {
        return this.food_items;
    }

    /**
     * shows food_item list
     * @returns object
     */
    get getFoodItem(): object {
        return this.food_item;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = { ...success };
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation // fetched food item list
    [Mutations.SET_FOOD_ITEM_LIST](payload) {
        this.food_items = payload
    }

    @Mutation // fetched food item
    [Mutations.SET_FOOD_ITEM](payload) {
        this.food_item = payload
    }

    @Action // fetch food items
    [Actions.FETCH_FOOD_ITEM_LIST]() {
        return ApiService.get("/food-items")
            .then(({data}) => {
                this.context.commit(Mutations.SET_FOOD_ITEM_LIST, data.food_items);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


    @Action // fetch food item
    [Actions.FETCH_FOOD_ITEM](payload) {
        return ApiService.get("/food-items/edit/"  + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_FOOD_ITEM, data.food_item);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // store food item
    [Actions.STORE_FOOD_ITEM](payload) {
        return ApiService.post("/food-items/store", payload)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // update food_item
    [Actions.UPDATE_FOOD_ITEM](payload) {
        return ApiService.withImageUpdate("/food-items/update/" + payload.id, payload.food_item_data)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action //delete food item
    [Actions.DELETE_FOOD_ITEM](payload) {
        return ApiService.delete("/food-items/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}