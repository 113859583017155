import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Item {
    items: Array<object>
}

export interface ItemInfo {
    errors: unknown;
    success: unknown;
    items: Item;
}

// export interface itemEntity {
//     items: Array<object>
// }

@Module
export default class ItemModule extends VuexModule implements ItemInfo {
    errors = {};
    success = {};
    items = {} as Item;

    // items: itemEntity[] = []

    /**
     * Get authentification success
     * @returns array
     */
    get getItemSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getItemErrors() {
        return this.errors;
    }

    /**
     * shows item list
     * @returns object
     */
    get getItems(): object {
        return this.items;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched item list data are saved in this function
    [Mutations.SET_ITEM_LIST](payload) {
        this.items = payload
    }

    @Action // fetch items
    [Actions.FETCH_ITEM_LIST]() {
        return ApiService.get("/items")
            .then(({data}) => {
                this.context.commit(Mutations.SET_ITEM_LIST, data.items);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // store item
    [Actions.STORE_ITEM](payload) {
        return ApiService.post("/items", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action // update item
    [Actions.UPDATE_ITEM](payload) {
        return ApiService.put("/items/" + payload.id, payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action //delete item
    [Actions.DELETE_ITEM](payload) {
        return ApiService.delete("/items/" + payload.id)
            .then(({data}) => {
                toast('success', data)
                this.context.commit(Mutations.SET_SUCCESS, data);
            })
            .catch(({response}) => {
                toast('error', response.data)
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
